import React from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';

export default function FormSelect({
    label,
    name,
    defaultValue,
    placeholder,
    required,
    rules,
    disabled,
    options,
    onChange,
    className,
    formClassName,
    dataE2e,
}) {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <Form.Item
            label={
                <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                    {label}
                </p>
            }
            name={name}
            required={required}
            rules={rules}
            className={`mt-[30px] ${formClassName}`}>
            <div className="relative w-full sm:w-[320px] mr-auto">
                <Select
                    suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                    className={`w-full h-[50px] text-base rounded-3xl modal-select ${className}`}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    variant={'borderless'}
                    disabled={disabled}
                    dropdownStyle={{ zIndex: 1002 }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    options={options}
                    onChange={onChange}
                    dataE2e={dataE2e}
                    dropdownRender={menu => (
                        <div className="!rounded-3xl" data-e2e={menu}>
                            {menu}
                        </div>
                    )}
                />
            </div>
        </Form.Item>
    );
}
