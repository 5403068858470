import { Button, Modal, Popover, Select, Divider, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Edit } from '../../utils/Images';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import {
    getCurrencySymbol,
    useWindowWidth,
    validateAmount,
    getCryptoFee,
    cryptoCurrencyIcon,
    CopyButton,
} from '../../utils/common';
import TransactionResultModal from './TransactionResultModal';
import { CryptoSelectModal } from './CryptoSelectModal';
import { CryptoAddresseModal } from './CryptoAddressModal';
import { useNavigate } from 'react-router-dom';
import CryptoPendingModal from './CryptoPendingModal';
const { Option } = Select;

const SendCryptoTransactionModal = ({ open, handleClose, onSubmit, client }) => {
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const [formData, setFormData] = useState({
        fromAddress: '',
        toAddress: undefined,
        cryptoAmount: null,
        cryptoId: 'ETH',
        selectedName: '',
    });
    const [fee, setFee] = useState(0);
    const windowWidth = useWindowWidth();
    const [transactionFee, setTransactionFee] = useState(0);
    const [error, setError] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [addressData, setAddressData] = useState([]);
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState({});
    const fromWalletOptions = balancesCrypto.filter(item => item.walletAddress);
    const [amountError, setAmountError] = useState('');
    const [addressActions, setAddressActions] = useState({
        add: false,
        edit: false,
    });
    const [step, setStep] = useState(1);
    const [feeError, setFeeError] = useState('');
    const [loading, setLoading] = useState(false);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const email = localStorage.getItem('email');
    const [processingModal, setProcessingModal] = useState({ open: false, msg: '' });
    const navigate = useNavigate();

    const getCryptoAddress = async data => {
        const request = {
            clientId: client?.clientId,
            email: email,
        };
        const response = await apiRequest('/get-crypto-address', 'POST', request);
        if (response.success) {
            setAddressData(response.data);
            return response.data;
        } else {
            message.error(response.error);
            return { error: response.error };
        }
    };
    const fetchData = async (cryptoId, open, email, client, setFee) => {
        if (!cryptoId || !open) return;
        const payload = {
            email: email,
            crypto: cryptoId,
            profile: client.feeProfile,
            transactionType: 'Send',
        };
        try {
            const cryptoFee = await getCryptoFee(payload);
            setFee(!cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
        } catch (error) {
            console.error('Failed to fetch crypto fee:', error);
        }
    };
    useEffect(() => {
        fetchData(formData.cryptoId, open, email, client, setFee);
    }, [formData.cryptoId, open]);

    useEffect(() => {
        if (balancesCrypto && open) {
            const walletAddress = balancesCrypto.find(
                crypto => crypto.currencyShortName === formData.cryptoId,
            )?.walletAddress;
            if (walletAddress) {
                setFormData({
                    ...formData,
                    fromAddress: walletAddress?.key,
                });
            } else {
                setFormData({
                    ...formData,
                    fromAddress: '',
                });
            }
        }
    }, [open, formData.cryptoId]);

    useEffect(() => {
        if (open) {
            getCryptoAddress();
        }
    }, [open, client]);

    const createTransaction = async () => {
        try {
            const blockchain = balancesCrypto.find(
                crypto => crypto.currencyShortName === formData.cryptoId,
            )?.blockchain;
            const fromAddress = fromWalletOptions.find(
                crypto => crypto.currencyShortName === formData.cryptoId,
            )?.walletAddress?.key;
            const body = {
                currencyType: 'crypto',
                type: 'Send',
                clientId: client?.clientId,
                email: localStorage.getItem('email'),
                transactionEmail: localStorage.getItem('user_name'),
                transactionFee,
                transactionDetails: {
                    cryptoId: formData.cryptoId,
                    blockchain,
                    cryptoAmount: totalAmount,
                    fromAddress: fromAddress,
                    toAddress: formData.toAddress,
                },
            };
            setLoading(true);
            const response = await onSubmit(body);
            if (response.success) {
                setStep(3);
            } else if (!response?.error.includes('processing')) {
                setError(response?.error);
                setStep(3);
            } else {
                setProcessingModal({ open: true, msg: response?.error });
            }
        } catch (error) {
            setLoading(false);
            setError(error.message || 'An error occurred while creating the transaction');
        }
    };
    const handleCloseAddressModal = () => {
        setAddressActions({ add: false, edit: false });
    };
    useEffect(() => {
        if (formData && fee) {
            const cryptoAmount = parseFloat(formData.cryptoAmount);
            const feeValue = parseFloat(fee);
            const transactionFee = cryptoAmount * feeValue;
            setTransactionFee(transactionFee);
            const adjustedAmount = cryptoAmount + transactionFee;
            const isValid = validateAmount(adjustedAmount, null, formData.cryptoId, balancesCrypto);
            setTotalAmount(isValid ? cryptoAmount + transactionFee : adjustedAmount);
        }
    }, [formData, fee]);
    const handleAmountChange = e => {
        setAmountError(validateAmount(e.target.value, null, formData.cryptoId, balancesCrypto));
        setFormData({
            ...formData,
            cryptoAmount: e.target.value,
        });
    };

    const onCancel = () => {
        setAmountError('');
        setLoading(false);
        setFormData({
            fromAddress: '',
            toAddress: undefined,
            cryptoAmount: null,
            cryptoId: 'ETH',
            selectedName: '',
        });
        setStep(1);
        setTransactionFee(0);
        setSelectedCryptoAddress({});
    };
    const handleStatus = () => {
        navigate('/transactions', {
            state: {
                clientId: client?.clientId,
            },
        });
    };
    const onReturnBack = () => {
        setAddressActions({
            edit: false,
            add: false,
        });
        setFormData({
            ...formData,
        });
    };
    const [popoverVisible, setPopoverVisible] = useState(null);

    const handlePopoverVisibility = (index, visible) => {
        if (visible) {
            setPopoverVisible(index);
        } else {
            setPopoverVisible(null);
            let elements = document.getElementsByClassName('address-popover');
            let elementsArray = [];
            if (elements && elements.length > 0) {
                elementsArray = Array.from(elements);
                for (const element of elementsArray) {
                    if (element.className.indexOf('ant-popover-hidden') == -1) {
                        element.className = element.className + ' ant-popover-hidden';
                    }
                }
            }
        }
    };
    const handleButtonAction = (action, event) => {
        event.stopPropagation();
        if (action === 'edit') {
            setAddressActions({ add: false, edit: true });
        } else {
            setAddressActions({ add: true, edit: false });
        }
    };
    const ContentEdit = ({ option, index }) => {
        return (
            <div className="w-[110px]" id={'address-popover-content-' + index}>
                <button
                    className="ml-2 pb-2 flex items-center gap-2"
                    onClick={event => {
                        handlePopoverVisibility(null, false);
                        handleButtonAction('edit', event);
                        setSelectedCryptoAddress(option);
                    }}>
                    {<img src={`${imageBaseUrl}${Edit}`} alt="" />}
                    <p className="text-[#51525C] text-sm font-medium">Edit</p>
                </button>
                <button
                    className="ml-0 flex items-center gap-2"
                    onClick={event => {
                        handlePopoverVisibility(null, false);
                        event.stopPropagation();
                    }}>
                    <CopyButton msg={option.address} />
                    <p className="text-[#51525C] text-sm font-medium">Copy</p>
                </button>
            </div>
        );
    };
    return (
        <>
            <Modal
                className="sell-crypto-modal common-mobile-view "
                width={570}
                centered
                title={
                    step === 1 ? (
                        <p className="text-2xl font-semibold relative flex">Send Crypto</p>
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => setStep(1)}
                            />
                            <p className="text-2xl font-semibold relative flex">Send Crypto</p>
                        </div>
                    ) : null
                }
                open={open && step !== 3 && !(addressActions?.add || addressActions?.edit)}
                onCancel={() => {
                    onCancel();
                    handleClose();
                }}
                handleClose={() => {
                    onCancel();
                    handleClose();
                }}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        onCancel();
                                        handleClose();
                                    }}
                                    className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="primary"
                                loading={step === 2 && loading}
                                disabled={
                                    !formData.cryptoAmount ||
                                    (amountError && amountError.length > 0) ||
                                    (feeError && feeError.length > 0)
                                }
                                onClick={async () => {
                                    try {
                                        if (step === 1) {
                                            setStep(2);
                                        } else {
                                            createTransaction();
                                        }
                                    } catch (error) {
                                        console.error('Validation failed:', error);
                                    }
                                }}
                                className="rounded-full px-8 py-3 w-full sm:w-auto h-[46px]"
                                data-e2e={
                                    step === 1
                                        ? 'Continue'
                                        : `${loading ? 'Processing' : 'Send Now'}`
                                }>
                                {step === 1 ? 'Continue' : `${loading ? 'Processing' : 'Send Now'}`}
                            </Button>
                        </div>
                    )
                }>
                {step === 1 ? (
                    <>
                        <div className="mt-[30px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">From Wallet:</label>
                            <div className="max-sm:pt-3 max-sm:pr-6 flex items-center max-sm:w-full w-4/6">
                                <input
                                    type="text"
                                    placeholder="Enter Wallet Amount"
                                    onChange={handleAmountChange}
                                    data-e2e="from-wallet"
                                    value={formData.cryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 py-[16px] pl-5 w-4/6"
                                />
                                <div className="w-1/3">
                                    <CryptoSelectModal
                                        value={formData.cryptoId}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                cryptoId: e,
                                            })
                                        }
                                        options={balancesCrypto}
                                        className="max-sm:w-[136px] !rounded-l-none w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        {amountError && (
                            <div className="jncAlert" role="alert">
                                {amountError}
                            </div>
                        )}
                        <div className=" my-[30px] grid grid-cols-3 items-center max-sm:flex-wrap relative">
                            <label className="text-base font-normal col-span-1 max-sm:col-span-3 text-start">
                                To:
                            </label>
                            <Select
                                value={formData?.toAddress}
                                placeholder={'Enter or select the address'}
                                className="bg-input max-sm:col-span-3 col-span-2 max-sm:mt-2 rounded-3xl h-14 address-select pl-2"
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, option) => {
                                    const selectedOption = addressData[value];
                                    if (selectedOption) {
                                        setFormData({
                                            ...formData,
                                            toAddress: `${
                                                selectedOption.nickName
                                                    ? selectedOption.nickName +
                                                      ': ' +
                                                      selectedOption.address
                                                    : selectedOption.address
                                            }`,
                                            selectedName: selectedOption.nickName,
                                            selectedAddress: selectedOption.address,
                                        });
                                    }
                                }}
                                dropdownRender={menu => (
                                    <>
                                        {' '}
                                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {menu}
                                        </div>
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <div
                                            className="w-full
                                                align-middle
                                                text-center text-[#18181B] text-base font-medium cursor-pointer"
                                            onClick={event => handleButtonAction('add', event)}>
                                            + Add new address
                                        </div>
                                    </>
                                )}>
                                {addressData.map((option, index) => {
                                    const popoverId = popoverVisible
                                        ? `simple-popover-${index}`
                                        : undefined;
                                    return (
                                        <Option key={index}>
                                            <div className="flex justify-between items-center w-full">
                                                <div>
                                                    <p className="text-base font-medium text-[#18181B] ">
                                                        {option.nickName}
                                                    </p>
                                                    <p className="text-[#70707B] text-sm font-normal">
                                                        {option.address}
                                                    </p>
                                                </div>
                                                <Popover
                                                    id={popoverId}
                                                    placement="leftTop"
                                                    trigger="click"
                                                    overlayInnerStyle={{
                                                        borderRadius: '4px',
                                                    }}
                                                    overlayClassName="address-popover"
                                                    content={
                                                        <ContentEdit
                                                            option={option}
                                                            index={index}
                                                        />
                                                    }
                                                    open={popoverVisible === index}
                                                    onOpenChange={visible =>
                                                        handlePopoverVisibility(index, visible)
                                                    }>
                                                    <button
                                                        type="button"
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            handlePopoverVisibility(
                                                                index,
                                                                popoverVisible === index
                                                                    ? false
                                                                    : true,
                                                            );
                                                        }}>
                                                        <MoreOutlined />
                                                    </button>
                                                </Popover>
                                            </div>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">Fee:</label>
                            {loading && <Spin indicator={<LoadingOutlined spin />} size="small" />}
                            <span className="text-base font-semibold">{fee * 100}%</span>
                        </div>
                        {feeError && (
                            <div className="jncAlert alert" role="alert">
                                Fee profile must be one of [Standard, VIP, Super VIP, Bespoke]
                            </div>
                        )}
                        <hr className="mb-[30px]"></hr>
                        <div className="mb-8 flex justify-between items-center">
                            <label className="text-lg font-normal text-start">Total:</label>
                            <span className="text-lg font-semibold">
                                {getCurrencySymbol(formData.cryptoId)} {totalAmount || 0} (
                                {formData.cryptoId})
                            </span>
                        </div>
                    </>
                ) : step === 2 ? (
                    <>
                        <p className="mt-7 text-base font-normal text-[#51525C] ">
                            Are you sure you want to send{' '}
                            <span className="font-semibold text-[#18181B]">
                                {totalAmount} {formData.cryptoId}
                            </span>{' '}
                            to this address?
                            <p>This is a permanent action and cannot be undone once</p>
                            confirmed.
                        </p>
                        <p className="text-base font-normal text-[#51525C] mt-3">
                            Please double check the address in full and confirm to continue.
                        </p>
                        <div className="border rounded-lg p-3 my-12">
                            <h1 className="text-base font-semibold">Transaction information:</h1>
                            <div className="mt-5">
                                <p className="text-sm font-normal text-[#51525C] ">Send:</p>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${cryptoCurrencyIcon[formData.cryptoId]}`}
                                            width={24}
                                            height={24}
                                            alt="img"
                                            className=""
                                        />
                                        <span className="text-xl font-normal text-[#26272B]">
                                            {crypto.blockchain}
                                        </span>
                                    </div>
                                    <p className="text-xl font-semibold text-[#26272B] ">
                                        {totalAmount} {formData.cryptoId}
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">
                                    Name address:
                                </p>
                                <p className="text-base font-medium text-[#18181B] ">
                                    {formData.selectedName}
                                </p>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <p className="text-base font-normal text-[#51525C] ">
                                    Recipient address::
                                </p>
                                <p className="text-base font-medium text-[#18181B] flex justify-between items-center w-full overflow-hidden">
                                    {formData.selectedAddress}
                                    <CopyButton msg={formData.selectedAddress} />
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}
            </Modal>

            <TransactionResultModal
                type={error ? error : 'success'}
                open={open && step === 3}
                title={error ? 'Error!' : 'Send Confirmed!'}
                handleClick={() => {
                    setStep(2);
                }}
                message={error ? error : 'Has been sent successfully!'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {getCurrencySymbol(formData.cryptoId)} {totalAmount || 0} (
                            {formData.cryptoId})
                        </p>
                    </>
                )}
            </TransactionResultModal>
            <CryptoAddresseModal
                open={(open && addressActions.edit) || addressActions.add}
                onCancel={handleCloseAddressModal}
                onBack={() => onReturnBack()}
                addressData={selectedCryptoAddress}
                addressActions={addressActions}
                getCryptoAddress={getCryptoAddress}
            />
            <CryptoPendingModal
                open={processingModal.open}
                onCancel={() => setProcessingModal(false)}
                error={processingModal.msg}
                handleConfirm={() => {
                    handleStatus();
                }}
            />
        </>
    );
};

export default SendCryptoTransactionModal;
