import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';
const { Option } = Select;

const MultiSelect = ({ options, placeholder, name, formValue, onChange }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [selectedValues, setSelectedValues] = useState();

    useEffect(() => {
        if (formValue) {
            setSelectedValues(formValue[name]);
        }
    }, [formValue]);

    const handleChange = selectedValues => {
        if (selectedValues.includes('All')) {
            if (selectedValues.length === options.length + 1) {
                setSelectedValues([]);
                onChange([]);
            } else {
                const allValues = options.map(option => option.value);
                setSelectedValues(allValues);
                onChange(allValues);
            }
        } else {
            setSelectedValues(selectedValues);
            onChange(selectedValues);
        }
    };

    const getDisplayValue = () => {
        if (selectedValues) {
            if (selectedValues.length === options.length) {
                return 'All';
            } else if (selectedValues.length > 1) {
                return `${selectedValues.length} selected`;
            } else if (selectedValues.length === 1) {
                return options.find(option => option.value === selectedValues[0])?.label;
            } else {
                return placeholder;
            }
        }
    };

    return (
        <Select
            suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
            mode="multiple"
            value={selectedValues}
            onChange={handleChange}
            placeholder={placeholder}
            className="w-full multi-select"
            maxTagCount={0}
            maxTagPlaceholder={getDisplayValue()}>
            <Option value="All">All</Option>
            {options.map(option => (
                <Option key={option.value} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default MultiSelect;
