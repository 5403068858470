import {
    CLIENT_DATA,
    SET_ACCOUNTS_DATA,
    SET_BALANCES_DATA,
    SET_CLIENT_FEE_SETTING,
    CLIENT_FIAT_BALANCE,
    SET_CRYPTO_BALANCES_DATA,
} from './actionTypes';

export const singleClientData = data => ({
    type: CLIENT_DATA,
    payload: data,
});

export const setBalancesData = value => {
    return {
        type: SET_BALANCES_DATA,
        value,
    };
};

export const setCryptoBalancesData = value => {
    return {
        type: SET_CRYPTO_BALANCES_DATA,
        value,
    };
};

export const setClientFeeSetting = value => {
    return {
        type: SET_CLIENT_FEE_SETTING,
        value,
    };
};

export function setAccountsData(value) {
    return {
        type: SET_ACCOUNTS_DATA,
        value,
    };
}
export const clientFiatBalance = data => ({
    type: CLIENT_FIAT_BALANCE,
    payload: data,
});
