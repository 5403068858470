import { Modal, Button, Space } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useWindowWidth } from '../../utils/common';
import ThemeButton from '../common/ThemeButton';

const WithdrawalAndTPPCommonModal = ({
    title,
    open,
    handleClose,
    children,
    footerText,
    error,
    isNewAccountTab,
    onSubmit,
    isEditing,
    updateBeneficiaryAccount,
    isWithdrawToSaveAccount,
    openDeleteAccountModal,
}) => {
    const windowWidth = useWindowWidth();
    return (
        <Modal
            width={windowWidth < 640 ? '100vw' : '570px'}
            className="deposit-modal common-mobile-view"
            title={<p className="text-2xl font-semibold relative">{title}</p>}
            open={open}
            destroyOnClose
            centered
            footer={[
                (isNewAccountTab || isWithdrawToSaveAccount) && (
                    <div className="flex flex-col text-end modal__footer" key="1">
                        {error && <div className="text-red-500">{error}</div>}
                        <div className="mt-6 w-full sm:w-max ant-btn-min-width ml-auto">
                            <ThemeButton
                                type="primary"
                                shape="round"
                                dataE2e={footerText}
                                text={footerText}
                                action={onSubmit}
                            />
                        </div>
                    </div>
                ),
                isEditing && (
                    <Space
                        direction={'horizontal'}
                        className="w-full pt-[24px] justify-between items-center modal__footer">
                        <div
                            onClick={openDeleteAccountModal}
                            className="rounded-full border border-gray-300  w-[44px] h-[44px] flex justify-center items-center hover:bg-black">
                            <DeleteOutlined className="text-red-500 text-xl" />
                        </div>
                        <Button
                            className="w-[140px] rounded-full"
                            key="submit"
                            type="primary"
                            onClick={updateBeneficiaryAccount}>
                            Apply
                        </Button>
                    </Space>
                ),
            ]}
            onCancel={handleClose}>
            <div className="max-h-[80vh] sm:max-h-[70vh] overflow-auto hide-scrollbar">
                {children}
            </div>
        </Modal>
    );
};

export default WithdrawalAndTPPCommonModal;
