import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../modals/CommonModal';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import { cryptoTransactionTypes } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';
import { PlusOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';

const { Option } = Select;

const CryptoFeeTab = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [form] = Form.useForm();
    const [openFeeModal, setOpenFeeModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [data, setData] = useState([]);
    const [formData, setformData] = useState({});
    const params = useParams();
    const email = localStorage.getItem('email');

    const columns = [
        {
            title: 'Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
        },
        {
            title: '% Fee',
            dataIndex: 'percentFee',
            key: 'percentFee',
            render: data => data || 0,
        },
        {
            title: 'Flat Fee',
            dataIndex: 'flatFee',
            key: 'flatFee',
            render: data => data || 0,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: row => (
                <>
                    <Button
                        className="border-0"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(row)}></Button>
                    <Button
                        className="border-0"
                        onClick={() => handleDeleteTransactionFee(row.transactionType)}
                        icon={<DeleteOutlined />}></Button>
                </>
            ),
        },
    ];

    const fetchClientsFee = async () => {
        const body = {
            email: email,
            clientId: params.id,
        };

        try {
            const response = await apiRequest('/get-client-fee-setting', 'POST', body);

            if (response.success && response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClientsFee();
    }, [params.id]);

    const handleClose = () => {
        setOpenFeeModal(false);
        setEditModal(false);
        setformData({});
        setErrorMsg('');
    };

    const handleEdit = row => {
        setEditModal(true);
        setOpenFeeModal(true);
        setformData({
            transactionType: row.transactionType,
            percentFee: row.percentFee,
            flatFee: row.flatFee,
        });
        form.setFieldsValue({
            type: row.transactionType,
            fee: row.percentFee,
            flatFee: row.flatFee,
        });
    };

    const handleApiCall = async payload => {
        try {
            const response = await apiRequest('/save-client-fee-setting', 'POST', payload);
            if (response.success) {
                fetchClientsFee();
                handleClose();
            } else {
                setErrorMsg(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createTransactionFee = async () => {
        const payload = {
            clientId: params.id,
            email,
            status: editModal ? 'update' : 'create',
            ...formData,
        };
        await handleApiCall(payload);
    };

    const handleDeleteTransactionFee = async type => {
        const payload = {
            clientId: params.id,
            email,
            transactionType: type,
            status: 'delete',
        };
        await handleApiCall(payload);
    };

    const handleSelectChange = value => {
        setformData({ ...formData, transactionType: value });
        form.setFieldsValue({ type: value });
        form.validateFields(['type']);
    };

    return (
        <div className="lg:pt-8 bg-white">
            <div className="flex items-center justify-between pt-4 lg:p-0 px-6 lg:px-0">
                <h1 className="lg:text-lg text-base font-semibold">Fee&apos;s</h1>
                <div className="font-medium text-sm">
                    <ThemeButton
                        type="primary"
                        style="mr-3"
                        icon={<PlusOutlined />}
                        dataE2e="create-new-transaction"
                        action={() => setOpenFeeModal(true)}
                        text="Add Fee"
                    />
                </div>
            </div>
            <div className="mt-6">
                <Table dataSource={data} columns={columns} pagination={false} />
            </div>
            <CommonModal
                title={editModal ? 'Edit Fee' : 'Add Fee'}
                open={openFeeModal}
                handleClose={handleClose}
                onFinish={() => form.submit()}
                footerText={editModal ? 'Save' : 'Add'}>
                <Form
                    onFinish={createTransactionFee}
                    form={form}
                    layout="horizontal"
                    className="mt-10 p-1 overflow-auto modal-form">
                    <Form.Item
                        label="Transaction Type"
                        name="type"
                        rules={[{ required: true, message: 'Transaction type is required!' }]}>
                        <div className="w-full sm:w-[320px] ml-auto">
                            <Select
                                suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                                placeholder="Select an option"
                                defaultValue={formData.transactionType}
                                onChange={handleSelectChange}
                                className="modal-select w-full float-end mb-3">
                                {cryptoTransactionTypes.map(item => (
                                    <Option value={item.value} key={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="% Fee"
                        name="fee"
                        rules={[{ required: true, message: 'Transaction fee is required!' }]}>
                        <div className="w-full sm:w-[320px] ml-auto">
                            <Input
                                placeholder="Enter % Fee"
                                defaultValue={formData.percentFee}
                                onChange={e =>
                                    setformData({ ...formData, percentFee: e.target.value })
                                }
                                className="crypto-fee__form-input"
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Flat fee"
                        name="flatFee"
                        rules={[{ required: true, message: 'Flat fee is required!' }]}>
                        <div className="w-full sm:w-[320px] ml-auto">
                            <Input
                                placeholder="Enter flat fee"
                                defaultValue={formData.flatFee}
                                onChange={e =>
                                    setformData({ ...formData, flatFee: e.target.value })
                                }
                                className="crypto-fee__form-input"
                            />
                        </div>
                    </Form.Item>
                    {errorMsg && <div className="text-red-500 mt-3">{errorMsg}</div>}
                </Form>
            </CommonModal>
        </div>
    );
};

export default CryptoFeeTab;
