import { Button, Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SuccessTick } from '../../utils/Images';
import { getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import TransactionResultModal from './TransactionResultModal';
import { FormInput, FormSelectCurrency } from '../forms';
import ThemeButton from '../common/ThemeButton';
import { fiatCurrencies } from '../../utils/constant';

const DepositFiatModal = ({ open, handleClose, selectedCurrency, onSubmit, client }) => {
    const [form] = Form.useForm();
    const clientData = useSelector(state => state.account.clientData);
    const balancesData = useSelector(state => state.account.balancesData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const email = localStorage.getItem('email');
    const [currency, setCurrency] = useState(
        selectedCurrency ? selectedCurrency.currencyShortName : '',
    );
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    useEffect(() => {
        if (selectedCurrency) {
            setCurrency(selectedCurrency?.currencyShortName);
            form.setFieldsValue({
                currency: selectedCurrency?.currencyShortName,
            });
        } else {
            setCurrency(fiatCurrencies[0].symbol);
            form.setFieldsValue({
                currency: fiatCurrencies[0].symbol,
            });
        }
    }, [open]);

    const createTransaction = async values => {
        try {
            const { amount, remitterName, reference } = values;
            const body = {
                adminRole: 'PaymentProviderAdmin',
                paymentProvider: localStorage.getItem('paymentProviderId'),
                email,
                client: clientData.clientId ? clientData.clientId : client,
                type: 'Deposit',
                currencyType: 'fiat',
                currency,
                transactionDetails: {
                    currency,
                    amount,
                    reference,
                    remitterName,
                },
                balance: {
                    balanceAmount: amount,
                    updated: new Date(),
                },
            };

            setLoading(true);
            const response = await onSubmit(body);
            handleClose();
            setStep(2);
            if (!response.success) {
                setError(response?.error);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error creating transaction:', error);
            setLoading(false);
        }
    };

    const onCancel = () => {
        form.resetFields();
        setCurrency('');
        setStep(1);
        setError('');
    };

    useEffect(() => {
        if (selectedCurrency) {
            setCurrency(selectedCurrency?.currencyShortName);
            form.setFieldsValue({
                currency: selectedCurrency?.currencyShortName,
            });
        }
    }, [selectedCurrency, balancesData]);

    return (
        <>
            <Modal
                width={570}
                centered
                className="transaction-modal deposit-modal"
                title="Deposit"
                open={open}
                onCancel={() => {
                    handleClose();
                    form.resetFields();
                }}
                handleClose={() => {
                    handleClose();
                    form.resetFields();
                }}
                footer={
                    <div className="flex ml-auto sm:w-max w-full and-btn-min-width">
                        <ThemeButton
                            loading={loading}
                            type="primary"
                            text={loading ? 'Processing' : 'Continue'}
                            action={() => form.submit()}
                            dataE2e={loading ? 'Processing' : 'Continue'}
                            shape="round"
                        />
                    </div>
                }>
                <Form
                    form={form}
                    labelCol={{
                        flex: '180px',
                    }}
                    colon={false}
                    layout="horizontal"
                    className="my-12 modal-form"
                    labelAlign="left"
                    labelWrap
                    onFinish={createTransaction}>
                    <FormSelectCurrency
                        label="Amount:"
                        name="amount"
                        selectName="currency"
                        required
                        rules={[
                            { required: true, message: 'Please enter an amount' },
                            {
                                validator: async (_, value) => {
                                    const numValue = Number(value);
                                    if (isNaN(numValue) || numValue <= 0) {
                                        return Promise.reject(
                                            new Error(
                                                'The amount must be the number greater than 0',
                                            ),
                                        );
                                    }
                                    const balance = balancesData.find(
                                        balance => balance.currencyShortName === currency.symbol,
                                    );
                                    if (balance?.balanceAmount < numValue) {
                                        return Promise.reject(
                                            new Error(
                                                'Please adjust the value as you have an insufficient balance',
                                            ),
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        placeholder="Enter amount"
                        dataE2e="enter-amount"
                        selectDefaultValue={currency}
                        options={(balancesData || []).map(data => ({
                            value: data.currencyShortName,
                            label: (
                                <div className="flex items-center">
                                    <img
                                        data-e2e={data.currencyShortName}
                                        src={`${imageBaseUrl}${data.currencyIcon}`}
                                        alt={data.currencyShortName}
                                        className="w-5 mr-[10px]"
                                    />
                                    {data.currencyShortName}
                                </div>
                            ),
                        }))}
                        onSelectChange={setCurrency}
                    />
                    <FormInput
                        label="Remitter name:"
                        name="remitterName"
                        placeholder="Enter remitter name"
                        dataE2e="remitter-name"
                        rules={[{ required: true, message: 'Please enter the remitter name' }]}
                    />
                    <FormInput
                        label="Reference:"
                        name="reference"
                        placeholder="Enter reference"
                        dataE2e="enter-reference"
                    />
                </Form>
            </Modal>

            <TransactionResultModal
                type={error ? error : 'success'}
                open={step === 2}
                handleClick={onCancel}
                title={error ? 'Error!' : 'Successful!'}
                message={error ? error : 'Has been deposited successfully!'}>
                {!error && (
                    <p className="text-xl font-medium text-primary">
                        {`${getCurrencySymbol(currency)} ${toFixedTrunc(form.getFieldValue('amount'))} ${form.getFieldValue('currency')}`}
                    </p>
                )}
            </TransactionResultModal>
        </>
    );
};

export default DepositFiatModal;
