import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import apiRequest from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import JunoFaAuthenticator from '../components/2fa/JunoFaAuthenticator';
import JunoFaVerification from '../components/2fa/JunoFaVerification';
import '../styles/auth.css';
import FormLogo from '../components/forms/FormLogo';
import ThemeButton from '../components/common/ThemeButton';

const Homepage = () => {
    const adminLogin2FA = useSelector(state => state.config.adminLogin2FA);
    const [loading, setLoading] = useState(false);
    const [show2FAVerificationModal, setShow2FAVerificationModal] = useState(false);
    const [showAuthenticatorModal, setShowAuthenticatorModal] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const onFinish = async values => {
        const body = values;
        setLoading(true);
        try {
            const response = await apiRequest('/admin-login', 'POST', body);
            if (response.success) {
                localStorage.setItem('adminRole', response.data.role);
                localStorage.setItem('email', values.email);
                localStorage.setItem('paymentProviderId', response.data.paymentProviderId);
                localStorage.setItem('name', response.data.name);
                const isLocalhost = window.location.hostname === 'localhost';
                const has2FA = isLocalhost
                    ? false
                    : adminLogin2FA === 'true' || adminLogin2FA === true;
                if (has2FA) {
                    if (
                        response.data.is2faActivated.enabled &&
                        response.data.is2faActivated.secret != null &&
                        response.data.is2faActivated.secret != 'null'
                    ) {
                        setShow2FAVerificationModal(true);
                    } else if (
                        response.data.is2faActivated.enabled &&
                        response.data.is2faActivated.secret == null
                    ) {
                        setShowAuthenticatorModal(true);
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    navigate('/dashboard');
                }
            } else {
                setError(response.error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    return (
        <div className="auth">
            {!show2FAVerificationModal && !showAuthenticatorModal && (
                <div className="auth__block">
                    <FormLogo />
                    <h2 className="auth__title">Log in</h2>
                    <p className="auth__subtitle">
                        Welcome back! <br />
                        Please enter your details
                    </p>
                    <div className="auth__form">
                        <Form
                            layout="vertical"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off">
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!',
                                    },
                                ]}>
                                <Input
                                    placeholder="Enter your email"
                                    className="h-11 px-3.5 py-2.5 !bg-transparent"
                                    data-e2e="email-field"
                                />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}>
                                <Input.Password
                                    placeholder="******"
                                    className="h-11 px-3.5 py-2.5"
                                    data-e2e="password-field"
                                />
                            </Form.Item>
                            <Link
                                className="auth__forgot"
                                to="/forgot-password"
                                data-e2e="forget-password">
                                Forgot Password
                            </Link>

                            {error !== '' && <div className="auth__errorText">{error}</div>}

                            <ThemeButton
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                dataE2e="submit-btn"
                                text="Log in"
                                shape="round"
                                size="large"
                            />
                        </Form>
                    </div>
                </div>
            )}

            {show2FAVerificationModal && <JunoFaVerification />}
            {showAuthenticatorModal && <JunoFaAuthenticator />}
        </div>
    );
};

export default Homepage;
