import React, { useEffect, useState } from 'react';
import { Form, Button, Select, Spin } from 'antd';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import useDebounce from '../../utils/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters, resetFilters } from '../../redux/actions/filtersActions';
import { ChevronBottomIcon } from '../../utils/Images';
import DrawerFilters from '../DrawerFilters';
import ThemeButton from '../common/ThemeButton';

const AccountFilterDrawer = props => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const { open, setOpen, getQuery, clearFilterDrawer, setClearFilterDrawer } = props;
    const [loading, setLoading] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);

    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    useEffect(() => {
        clearFilterDrawer && resetFilteredValues();
    }, [clearFilterDrawer]);

    const handleCloseDrawer = () => {
        getQuery(filters);
        setOpen(false);
        setDropdownData([]);
    };

    const getClientDetails = useDebounce((type, value) => {
        onChangeFilter(type, value);
    }, 500);

    const onChangeFilter = async (type, value) => {
        let filterParams = {
            paymentProvider: paymentProvider,
            whiteLabelId: whiteLabelId,
        };

        const body = {
            email,
            filterType: type,
            filterValue: value,
            filterParams,
        };

        setLoading(true);
        try {
            const response = await apiRequest('/filter-clients', 'POST', body);

            if (response.success) {
                setLoading(false);
                if (response.data) {
                    const formattedOptions = response.data.clientsData.map(client => ({
                        value: client,
                        label: client,
                    }));
                    setDropdownData(formattedOptions);
                }
            } else {
                return [];
            }
        } catch (error) {
            setLoading(false);
            return [];
        }
    };

    const resetFilteredValues = () => {
        dispatch(resetFilters());
        setClearFilterDrawer(false);
        setDropdownData([]);
    };

    const handleFilterChange = (type, value) => {
        dispatch(updateFilters({ ...filters, [type]: value }));
    };

    const handleSelectChange = () => {
        setDropdownData([]);
    };

    function FilterButtons() {
        return (
            <div className="py-[8px] sm:py-[16px] lg:py-[0] sm:w-max sm:ml-auto grid grid-cols-[auto_1fr] lg:mt-6 gap-2">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={resetFilteredValues}
                    dataE2e="reset-btn"
                    text="Reset"
                />
                <ThemeButton
                    type="primary"
                    text="Search"
                    dataE2e="search-btn"
                    icon={<SearchOutlined />}
                    action={handleCloseDrawer}
                />
            </div>
        );
    }

    return (
        <DrawerFilters
            title="Filters"
            onClose={handleCloseDrawer}
            open={open}
            footer={<FilterButtons />}>
            <Form.Item label={'Account ID'} name={'clientId'} className="w-full">
                <div className="">
                    <SearchOutlined className="custom-search-icon" />
                    <Select
                        showSearch={true}
                        value={filters['clientId']}
                        placeholder="Select Account ID"
                        data-e2e="select-account-id"
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={value => getClientDetails('clientId', value)}
                        onChange={value => handleFilterChange('clientId', value)}
                        notFoundContent={null}
                        options={(dropdownData || []).map(data => ({
                            value: data.value,
                            label: data.label,
                        }))}
                        onSelect={handleSelectChange}
                        className="filter-select"
                    />
                </div>
            </Form.Item>
            <Form.Item label={'Full Name'} name={'name'} className="w-full">
                <div className="">
                    <SearchOutlined className="custom-search-icon" />
                    <Select
                        showSearch={true}
                        value={filters['name']}
                        placeholder="Select Full Name"
                        data-e2e="select-full-name"
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        loading={loading}
                        filterOption={false}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        onSearch={value => getClientDetails('name', value)}
                        onChange={value => handleFilterChange('name', value)}
                        options={(dropdownData || []).map(data => ({
                            value: data.value,
                            label: data.label,
                        }))}
                        onSelect={handleSelectChange}
                        className="filter-select"
                    />
                </div>
            </Form.Item>

            <Form.Item label={'Email'} name={'clientEmail'} className="w-full">
                <div className="">
                    <SearchOutlined className="custom-search-icon" />
                    <Select
                        showSearch={true}
                        value={filters['clientEmail']}
                        placeholder="Select Email"
                        data-e2e="select-email"
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={value => getClientDetails('clientEmail', value)}
                        onChange={value => handleFilterChange('clientEmail', value)}
                        notFoundContent={null}
                        options={(dropdownData || []).map(data => ({
                            value: data.value,
                            label: data.label,
                        }))}
                        onSelect={handleSelectChange}
                        className="filter-select"
                    />
                </div>
            </Form.Item>

            <Form.Item label={'Mobile Number'} name={'phoneNumber'} className="w-full">
                <div className="">
                    <SearchOutlined className="custom-search-icon" />
                    <Select
                        showSearch={true}
                        value={filters['phoneNumber']}
                        placeholder="Select Mobile Number"
                        data-e2e="select-mobile-number"
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={value => getClientDetails('phoneNumber', value)}
                        onChange={value => handleFilterChange('phoneNumber', value)}
                        notFoundContent={null}
                        options={(dropdownData || []).map(data => ({
                            value: data.value,
                            label: data.label,
                        }))}
                        onSelect={handleSelectChange}
                        className="filter-select"
                    />
                </div>
            </Form.Item>

            <Form.Item label={'Account Status'} name={'active'} className="w-full">
                <div className="w-full custom-select">
                    <Select
                        suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                        dropdownRender={menu => <>{menu}</>}
                        placeholder="Select Account Status"
                        value={filters['active']}
                        onChange={value => handleFilterChange('active', value)}
                        options={[
                            { label: 'All', value: 'all' },
                            { label: 'Active', value: 'true' },
                            { label: 'Inactive', value: 'false' },
                        ]}
                        className="w-full custom-select"
                    />
                </div>
            </Form.Item>

            {/* This commented code is present in design but as we don't have all data for now we can use that code later */}
            {/* <Form.Item
                        label="Rejection Reason"
                        name="rejectionReason"
                        className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>
                   <Form.Item label="CA AML Status" name="amlStatus" className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="PEP Status" name="pepStatus" className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="KYC Status" name="kycStatus" className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item> 
                    <Form.Item label="Risk Level" name="riskLevel" className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Address Approval"
                        name="addressApproval"
                        className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Bank Statement"
                        name="bankStatement"
                        className="w-full">
                        <Select placeholder="Select an option" className="w-full custom-select">
                            <Option value="option1">Option 1</Option>
                            <Option value="option2">Option 2</Option>
                            <Option value="option3">Option 3</Option>
                        </Select>
                    </Form.Item>*/}
            {/* <Form.Item
                    label="AML White List"
                    name="amlWhitelist"
                    className="w-full mt-6">
                    <Checkbox indeterminate>Whitelisted accounts</Checkbox>
                </Form.Item> */}
            {/* <div className="flex flex-wrap gap-6 mt-6"> */}
            {/* <Form.Item label="Last Login Date" name="lastLogin" className="w-full">
                        <DatePicker className="w-full p-2" />
                    </Form.Item>
                    <Form.Item label="To" name="to" className="w-full">
                        <DatePicker className="w-full p-2" />
                    </Form.Item> */}

            {/* <Form.Item label="VIP Level" name="vipLevel" className="w-full">
                        <Input
                            placeholder="Enter Email"
                            prefix={<SearchOutlined />}
                            className="w-full p-2"
                        />
                    </Form.Item> */}
        </DrawerFilters>
    );
};

export default AccountFilterDrawer;
