import React from 'react';
import { Tabs } from 'antd';
import { cryptoDetailsNav } from '../../utils/constant';

const CryptoTab = () => {
    return (
        <div className="col-span-2 bg-white">
            <div className=" pt-7 crypto-tab-tabs">
                <Tabs
                    defaultActiveKey="0"
                    items={cryptoDetailsNav.slice(0)}
                    className="transaction-detail crypto-tab"
                />
            </div>
        </div>
    );
};

export default CryptoTab;
