export const formatAmount = amount => {
    if (amount >= 1_000_000_000_000) {
        return (amount / 1_000_000_000_000).toFixed(1) + 't';
    } else if (amount >= 1_000_000_000) {
        return (amount / 1_000_000_000).toFixed(1) + 'b';
    } else if (amount >= 1_000_000) {
        return (amount / 1_000_000).toFixed(1) + 'm';
    } else if (amount >= 1_000) {
        return (amount / 1_000).toFixed(1) + 'k';
    }
    return amount.toFixed(2);
};
