import { DatePicker, Select, Table } from 'antd';
import React from 'react';
const { Option } = Select;

const ActivityTab = () => {
    const columns = [
        {
            title: 'Event Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Event Descriptions',
            dataIndex: 'descriptions',
            key: 'descriptions',
        },
        {
            title: 'Event Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Admin who changes',
            dataIndex: 'admin',
            key: 'admin',
        },
    ];

    const dataSource = [
        {
            key: '1',
            type: 'PASSWORD_RESET',
            descriptions: 'admin - old value:Standard, new value:“Noir”',
            date: '24.05.21 16:55',
            admin: 'Emerald Hopkins',
        },
        {
            key: '2',
            type: 'PASSWORD_RESET',
            descriptions: 'admin - old value:Standard, new value:“Noir”',
            date: '24.05.21 16:55',
            admin: 'Emerald Hopkins',
        },
    ];
    return (
        <div className="mt-5">
            <h1 className="text-lg font-semibold">Activity Log</h1>
            <div className="flex gap-5 mt-5">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Date:</span>
                    <DatePicker className="w-full p-2 mt-2" />
                </div>
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Admin action:</span>
                    <Select placeholder="Select an option" className="w-full custom-select mt-2">
                        <Option value="option1">Option 1</Option>
                        <Option value="option2">Option 2</Option>
                        <Option value="option3">Option 3</Option>
                    </Select>
                </div>
            </div>
            <div className="mt-10">
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            </div>
        </div>
    );
};

export default ActivityTab;
