import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ClockCircleOutlined,
    CloseOutlined,
    CheckOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import StatusSummaryCard from './StatusSummaryCard';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { transactions, statusCode, transactionTypes } from '../utils/constant';
import { useWindowWidth } from '../utils/common';
import apiRequest from '../utils/api';
import { useNavigate } from 'react-router-dom';
import Loading, { LoadingAvatarSkeleton } from './common/Loading';

const dateFormat = 'DD.MM.YYYY';
const apiDateFormat = 'YYYY-MM-DD';

function getStatus(code) {
    for (const [status, codes] of Object.entries(statusCode)) {
        if (codes.includes(code)) {
            return status;
        }
    }
    return 'N/A';
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const FiatDashboard = ({ checkedTransaction }) => {
    const [loading, setLoading] = useState(false);

    const [selectedTransaction, setSelectedTransaction] = useState('All Transactions');
    const [transactionCounts, setTransactionCounts] = useState({
        all: 0,
        completed: 0,
        processing: 0,
        pending: 0,
        failed: 0,
        rejected: 0,
    });
    const [transactionDetails, setTransactionDetails] = useState();
    const [category, setCategory] = useState();
    const [seriesData, setSeriesData] = useState([]);

    const windowWidth = useWindowWidth();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const dateFilter = useSelector(state => state.dateFilter);
    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const globalCurrencyType = 'fiat';

    const getTransactionDetails = useCallback(async () => {
        const body = {
            email,
            paymentProvider,
            whiteLabelId,
            startDate: dateFilter?.startDate,
            endDate: dateFilter?.endDate,
        };

        try {
            const response = await apiRequest('/get-dashboard-transaction-details', 'POST', body);

            if (response.success && response.data) {
                const { allTransactionDetails, transactionType } = response.data;
                const result = {
                    allTransactions: {},
                    typeTransactions: {},
                };

                allTransactionDetails.forEach(item => {
                    if (item.currencyType === globalCurrencyType) {
                        const status = getStatus(item.statusCode);
                        if (!result.allTransactions[status]) {
                            result.allTransactions[status] = { count: 0, totalUSDAmount: 0 };
                        }
                        result.allTransactions[status].count += item.count;
                        result.allTransactions[status].totalUSDAmount += item.totalUSDAmount;
                    }
                });
                transactionType.forEach(item => {
                    if (item.currencyType === globalCurrencyType) {
                        const type = item.type;
                        const status = getStatus(item.statusCode);

                        if (!result.typeTransactions[type]) {
                            result.typeTransactions[type] = {};
                        }
                        if (!result.typeTransactions[type][status]) {
                            result.typeTransactions[type][status] = { count: 0, totalUSDAmount: 0 };
                        }
                        result.typeTransactions[type][status].count += item.count;
                        result.typeTransactions[type][status].totalUSDAmount += item.totalUSDAmount;
                    }
                });

                setTransactionDetails(result);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dateFilter]);

    const getTransactionGraph = useCallback(async () => {
        const body = {
            email,
            paymentProvider,
            whiteLabelId,
            startDate: dateFilter?.startDate,
            endDate: dateFilter?.endDate,
        };

        try {
            const response = await apiRequest('/get-dashboard-transaction-graph', 'POST', body);

            if (response.success) {
                if (response.data) {
                    const { labels, dataSets } = response.data;

                    const capitalizedDataSets = dataSets.map(item => ({
                        ...item,
                        name: capitalizeFirstLetter(item.name),
                    }));

                    setCategory(labels);
                    setSeriesData(capitalizedDataSets);
                }
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dateFilter]);

    const getTransactionCounts = useCallback(async () => {
        const body = {
            email,
            paymentProvider,
            whiteLabelId,
            startDate: dateFilter?.startDate,
            endDate: dateFilter?.endDate,
        };

        try {
            const response = await apiRequest('/get-dashboard-transaction-counts', 'POST', body);

            if (response.success) {
                if (response.success && response.data) {
                    const { totalTransactionCount, statusCounts } = response.data;

                    const counts = statusCounts.reduce(
                        (acc, { count, code, currencyType }) => {
                            if (currencyType === 'fiat') {
                                acc.all += count;
                                for (const [status, codes] of Object.entries(statusCode)) {
                                    if (codes.includes(code)) {
                                        acc[status] += count;
                                        break;
                                    }
                                }
                            }
                            return acc;
                        },
                        { all: 0, processing: 0, pending: 0, completed: 0, failed: 0, rejected: 0 },
                    );

                    setTransactionCounts(prevCounts => ({
                        ...prevCounts,
                        ...counts,
                        total: totalTransactionCount,
                    }));
                }
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dateFilter]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await Promise.all([
                getTransactionDetails(),
                getTransactionGraph(),
                getTransactionCounts(),
            ]);
            setLoading(false);
        };

        fetchData();
    }, [dateFilter]);

    const transactionsCounts = [
        {
            id: 1,
            title: 'Total Transactions',
            value: 'totalTransactions',
            count: transactionCounts?.all,
            icon: (
                <img
                    alt="transactions"
                    src={`${imageBaseUrl}/adminUi/exchangeIcon.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
            color: '#475467',
        },
        {
            id: 2,
            title: 'Completed',
            value: 'completed',
            count: transactionCounts?.completed,
            icon: <CheckOutlined className="text-[30px]" />,
            color: '#039855',
        },
        {
            id: 3,
            title: 'Processing',
            value: 'processing',
            count: transactionCounts?.processing,
            icon: <ClockCircleOutlined className="text-[30px]" />,
            color: '#1570EF',
        },
        {
            id: 4,
            title: 'Pending',
            value: 'pending',
            count: transactionCounts?.pending,
            icon: <ExclamationCircleOutlined className="text-[30px]" />,
            color: '#F79009',
        },
        {
            id: 5,
            title: 'Rejected',
            value: 'rejected',
            count: transactionCounts?.rejected,
            icon: (
                <img
                    alt="transactions"
                    src={`${imageBaseUrl}/adminUi/rejected.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
            color: '#7A5AF8',
        },
        {
            id: 6,
            title: 'Failed',
            value: 'failed',
            count: transactionCounts?.failed,
            icon: <CloseOutlined className="text-[30px]" />,
            color: '#D92D20',
        },
    ];

    const statusColors = transactionsCounts
        .filter(transaction => transaction.value !== 'totalTransactions')
        .reduce((acc, transaction) => {
            acc[transaction.value] = transaction.color;
            return acc;
        }, {});

    const options = useMemo(
        () => ({
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
            },
            colors: seriesData.map(series => statusColors[series.name.toLowerCase()] || '#000000'),
            xaxis: {
                categories: category,
                labels: {
                    show: true, // Show x-axis labels
                },
                title: {
                    text: 'Time',
                    offsetY: -15, // Adjust the vertical position
                },
            },
            yaxis: {
                min: 0,
                max: Math.max(...seriesData.flatMap(series => series.data)),
                tickAmount: 6,
                labels: {
                    // Format labels with "k"
                    formatter: value => {
                        if (value >= 1000) {
                            return `${Math.floor(value / 1000)}k`;
                        }
                        return Math.floor(value);
                    },
                    show: true, // Show y-axis labels
                },
                title: {
                    text: 'Transaction Counts',
                    rotate: -90, // Rotate the label to be vertical
                    offsetX: 0, // Adjust the horizontal position
                },
            },
            stroke: {
                curve: 'smooth',
                width: 1, // Set line width to be thinner
            },
        }),
        [category, seriesData],
    );

    const TransactionGraph = () => {
        const transactionChart = checkedTransaction?.find(
            item => item.value === 'transactionChart',
        );
        const transactionStatus = checkedTransaction
            ?.filter(item => item?.checked)
            ?.map(item => item?.value);

        const transactionData = transactionsCounts.filter(transaction =>
            transactionStatus?.includes(transaction?.value),
        );

        return (
            <>
                {transactionChart && transactionChart.checked && (
                    <div
                        className={`bg-white rounded-xl p-6 ${transactionData?.length > 0 ? 'lg:basis-2/3' : 'w-full'}`}>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                <p className="pb-6 text-lg font-semibold text-primary">
                                    Transactions
                                </p>
                                <div className="h-[100%]">
                                    {seriesData && (
                                        <ReactApexChart
                                            options={options}
                                            series={seriesData}
                                            type="line"
                                            height={windowWidth < 1024 ? 347 : 600}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };

    const TransactionStatus = () => {
        const transactionChart = checkedTransaction?.find(
            item => item.value === 'transactionChart',
        );
        const transactionStatus = checkedTransaction
            ?.filter(item => item?.checked)
            ?.map(item => item?.value);

        const transactionData = transactionsCounts.filter(transaction =>
            transactionStatus?.includes(transaction?.value),
        );

        const handleStatusCountCard = code => {
            navigate('/transactions', {
                state: {
                    transactionType: transactionTypes
                        .slice(0, 5)
                        .map(transaction => transaction.value),
                    transactionStatus: code.value,
                    dateFilter,
                },
            });
        };

        const TransactionCard = ({ status }) => {
            if (loading) return <LoadingAvatarSkeleton />;

            return (
                <div className="" onClick={() => handleStatusCountCard(status)}>
                    <div className="border-solid border transition-all border-transparent hover:border-[#d1d1d6] p-4 lg:p-6 bg-white rounded-xl flex justify-between">
                        <div className="w-16 h-16 bg-[#F4F4F5] rounded-full p-4 flex justify-center items-center">
                            {status.icon}
                        </div>
                        <div className="text-right">
                            <h3 className="text-2xl font-medium text-primary">{status.count}</h3>
                            <p style={{ color: status.color }} className="text-base font-medium">
                                {status.title}
                            </p>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div
                className={`grid grid-cols-1 md:grid-cols-2 lg:flex lg:flex-col gap-[8px] cursor-pointer justify-between ${transactionChart?.checked ? transactionData?.length > 0 && 'lg:basis-1/3' : 'w-full'}`}>
                {transactionData.map((status, index) => (
                    <TransactionCard key={index} status={status} />
                ))}
            </div>
        );
    };

    function TransactionDashboard() {
        const transactionAllType = checkedTransaction
            ?.filter(item => !item.type || item.type === globalCurrencyType)
            ?.filter(item => item?.checked)
            ?.map(item => item?.label);

        const transactionData = transactions.filter(transaction =>
            transactionAllType?.includes(transaction?.label),
        );

        return (
            <>
                <div className="flex gap-2 mt-6 lg:hidden overflow-x-auto">
                    {transactionData
                        .slice(-6)
                        .sort((a, b) => a.key - b.key)
                        .map((transaction, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedTransaction(transaction?.label)}
                                className={`px-[8px] py-[6px] min-w-fit w-full ${transaction?.label === selectedTransaction ? 'border-b-2 border-primary bg-[#E4E4E7]' : ''} rounded-md text-primary `}>
                                {transaction?.label}
                            </button>
                        ))}
                </div>
                <div className="mt-6 grid lg:grid-cols-3 gap-3">
                    {transactionData
                        .filter(trans =>
                            windowWidth < 1024 ? trans?.label === selectedTransaction : true,
                        )
                        .map((transaction, key) => (
                            <div key={key}>
                                <StatusSummaryCard
                                    key={key}
                                    title={transaction}
                                    data={
                                        transaction?.label === 'All Transactions'
                                            ? transactionDetails?.allTransactions
                                            : transactionDetails?.typeTransactions[
                                                  transaction?.value
                                              ] || {}
                                    }
                                    dateFilter={dateFilter}
                                    loading={loading}
                                />
                            </div>
                        ))}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3">
                <TransactionGraph />
                <TransactionStatus />
            </div>
            <TransactionDashboard />
        </>
    );
};

export default FiatDashboard;
