import { Button, Divider } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CopyButton } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../modals/CommonModal';
import { FileOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';

const Overview = ({ clientData, transactionDetails }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const navigate = useNavigate();
    const [attachmentModal, setAttachmentModal] = useState(false);
    const details = [
        { label: 'Account name', value: clientData.name || 'NA' },
        { label: 'Email', value: clientData.clientEmail || 'NA' },
        { label: 'Phone', value: clientData.phoneNumber || 'NA' },
        { label: 'Address', value: clientData.address || 'NA' },
        //{ label: 'Address line 1', value: clientData.address || 'NA' },
        //{ label: 'Address line 2', value: 'NA' },
        { label: 'City', value: 'NA' },
        { label: 'State / Province / Region', value: 'NA' },
        { label: 'ZIP / Postal code', value: clientData.postCode || 'NA' },
        { label: 'Country', value: clientData.country || 'NA' },
        {
            label: 'Registration Date',
            value: moment(clientData.createdDate).format('DD.MM.YY HH:mm'),
        },
    ];

    const handleAttachmentModal = () => {
        setAttachmentModal(!attachmentModal);
    };
    return (
        <div className="bg-white pb-5">
            <h1 className="lg:hidden text-base font-semibold p-5">Transaction details</h1>
            <div className="text-sm font-normal px-6 ">
                <dl className="flex flex-col gap-3 lg:gap-0">
                    {details.map((detail, index) => (
                        <div key={index} className="flex justify-between py-1">
                            <dt className="text-darkGray">{detail.label}:</dt>
                            <dd className="text-[#18181B] w-auto">
                                <div className="max-w-[250px] break-words">
                                    {detail.value} <CopyButton msg={detail.value} />
                                </div>
                            </dd>
                        </div>
                    ))}
                </dl>
                {clientData.clientId && (
                    <ThemeButton
                        text="See more"
                        style="mt-5"
                        autoWidth={true}
                        action={() => navigate(`/accounts/${clientData.clientId}`)}
                    />
                )}
            </div>
            {/* <div className="px-6 text-sm">
                <div className="flex justify-between">
                <p className="text-[#51525C]">Account AML status:</p>
                <p className="font-medium text-[#1570EF]">Sanction scanner</p>
                </div>
                <Divider className="my-3" />
                <div className="flex justify-between">
                    <p className="text-[#51525C]">Account KYC status:</p>
                    <p className="font-medium text-[#1570EF]">Enqura KYC modal</p>
                    </div>
                <Divider className="my-3" />
                <p className="text-[#51525C]">AML Status:</p>
                <Button className="mt-2 flex items-center gap-1 rounded-lg border border-[#D1D1D6] text-sm font-medium text-[#51525C] px-3 py-2">
                <img src={`${imageBaseUrl}${DocumentIcon}`} alt="arrow up" />
                Open Sanction scanner
                </Button>
                <p className="text-[#51525C] mt-3">Last checked: 15.09.2024 / 15:35</p>
                </div>
                
                <Divider className="my-5" /> */}
            {transactionDetails?.attachment && (
                <>
                    <Divider className="my-5" />
                    <h1 className="font-semibold text-base mb-5 px-5">Attachments</h1>
                    <div className="flex items-center gap-2 px-5 lg:pb-0 pb-5 w-full">
                        {transactionDetails?.attachment.slice(0, 3).map((item, index) => {
                            return (
                                <a
                                    href={item}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}>
                                    <div className="w-[88px] h-[88px] rounded-xl flex items-center flex-col justify-center bg-[#F4F4F5]">
                                        <FileOutlined className="text-2xl" />
                                        <span className="text-sm p-1 font-medium text-[#3F3F46] ">
                                            file{index}
                                        </span>
                                    </div>
                                </a>
                            );
                        })}
                        {transactionDetails?.attachment.length > 3 && (
                            <div
                                className="w-[88px] cursor-pointer h-[88px] rounded-xl object-cover flex items-center justify-center bg-[#F4F4F5]"
                                onClick={handleAttachmentModal}>
                                +{transactionDetails.attachment.length - 3}
                            </div>
                        )}
                    </div>
                </>
            )}
            <CommonModal
                title="Attachments"
                open={attachmentModal}
                handleClose={handleAttachmentModal}>
                <div className="grid items-center lg:grid-cols-4 grid-cols-2 gap-5 px-5 lg:pb-0 pb-5">
                    {transactionDetails.attachment?.map((item, index) => {
                        return (
                            <a href={item} target="_blank" rel="noopener noreferrer" key={index}>
                                <div className="lg:w-[88px] w-[168px]  lg:h-[88px] h-[114px] rounded-xl flex items-center flex-col justify-center bg-[#F4F4F5]">
                                    <FileOutlined className="text-2xl" />
                                    <span className="text-sm p-1 font-medium text-[#3F3F46] ">
                                        file {index + 1}
                                    </span>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </CommonModal>
        </div>
    );
};

export default Overview;
