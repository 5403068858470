import { Button } from 'antd';

// type: primary (filled), default (transparent)
// variant: default (square), rounded

export default function ThemeButton({
    style,
    icon,
    action,
    text,
    dataE2e,
    htmlType,
    type,
    shape,
    loading,
    size,
    disabled,
    key,
    autoWidth,
}) {
    return (
        <Button
            size={size ? size : 'middle'}
            shape={shape ? shape : 'default'}
            type={type ? type : 'default'}
            loading={loading}
            block={!autoWidth}
            disabled={disabled}
            className={`${style ? style : ''}`}
            {...(htmlType && { htmlType: htmlType })}
            {...(key && { key: key })}
            {...(dataE2e && { 'data-e2e': dataE2e })}
            {...(icon && { icon: icon })}
            {...(action && { onClick: action })}>
            {!loading && text}
        </Button>
    );
}
