import { Button, Divider, Drawer, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoreOutlined, CloseOutlined } from '@ant-design/icons';
import { navbarItems } from '../../utils/constant';
import TransferModal from '../modals/TransferModal';
import WithdrawalAndTPPFiatModal from '../modals/WithdrawalAndTPPFiatModal';
import apiRequest from '../../utils/api';
import { useParams } from 'react-router-dom';
import { getCurrencySymbol, toFixedTrunc } from '../../utils/common';

import FXFiatModal from '../modals/FXFiatModal';
import DepositFiatModal from '../modals/DepositFiatModal';
import { getBalancesData, getBeneficiaryList } from '../../utils/common';
import Loading from '../common/Loading';
import { Freeze } from '../../utils/Images';
import ThemeButton from '../common/ThemeButton';

const BalanceDetailsTab = () => {
    const dispatch = useDispatch();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [actionTypeModal, setActionTypeModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [balanceLoading, setBalanceLoading] = useState(true);
    const {
        clientData,
        balancesData,
        savedBeneficiaryAccounts: beneficiaryList,
    } = useSelector(state => state.account);

    const email = localStorage.getItem('email');
    const { id } = useParams();

    useEffect(() => {
        getBalancesData(
            dispatch,
            clientData.clientId,
            email,
            clientData.clientEmail,
            setBalanceLoading,
        );
        getBeneficiaryList(dispatch, email, clientData.clientEmail);
    }, []);

    const openModalAction = (modalType, selectedCurrency) => {
        setIsModalOpen(true);
        setOpenModal(modalType);
        setSelectedCurrency(selectedCurrency);
    };

    const onModalClose = () => {
        setIsModalOpen(false);
        setOpenModal('');
    };

    const DrawerHeader = () => {
        return (
            <div className="flex justify-between items-start">
                <div className="flex items-start gap-2">
                    <img
                        className="w-[40px] h-[40px] rounded-full object-cover"
                        src={`${imageBaseUrl}${selectedCurrency.currencyIcon}`}
                        alt="Avatar"
                    />
                    <div className="">
                        <h1 className="text-lg font-semibold">
                            {getCurrencySymbol(selectedCurrency.currencyShortName)}{' '}
                            {toFixedTrunc(selectedCurrency.balanceAmount)}
                        </h1>
                        <p className="text-base font-normal">{selectedCurrency.currencyFullName}</p>
                    </div>
                </div>
                <CloseOutlined onClick={() => setActionTypeModal(false)} />
            </div>
        );
    };

    const createTransaction = async body => {
        try {
            const response = await apiRequest('/create-client-transaction-merchant', 'POST', body);
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    clientData?.clientId,
                    email,
                    clientData?.clientEmail,
                    setBalanceLoading,
                );
                return { success: true };
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    return (
        <div className="md:pt-8 px-6 lg:px-0 bg-white fiat-balance">
            <div className="flex items-center justify-between pt-4 lg:p-0">
                <h1 className="md:text-lg text-base font-semibold">Currencies</h1>
                <div className="font-medium text-sm ">
                    <ThemeButton
                        style="mr-3"
                        text="Freeze all"
                        icon={
                            <img
                                src={`${imageBaseUrl}${Freeze}`}
                                alt="freeze"
                                className="w-6 h-6 inline"
                            />
                        }
                    />
                </div>
            </div>
            <Divider className="hidden lg:block my-6" />
            <div className="pt-6 lg:p-0 h-fit fiat-balance-details">
                {balanceLoading ? (
                    <div className="flex flex-col gap-5">
                        <Loading />
                    </div>
                ) : (
                    balancesData.map(balance => (
                        <>
                            <div
                                className="grid grid-cols-3 items-center justify-between"
                                data-e2e={balance.currencyShortName}
                                data-e2e-amount={toFixedTrunc(balance.balanceAmount)}
                                key={balance.currencyShortName}>
                                <div className="flex items-center gap-5">
                                    <img
                                        className="w-[30px] h-[30px] rounded-full object-cover"
                                        src={`${imageBaseUrl}${balance.currencyIcon}`}
                                        alt="Avatar"
                                    />
                                    <h1 className="text-sm font-normal">
                                        {getCurrencySymbol(balance.currencyShortName)}{' '}
                                        {toFixedTrunc(balance.balanceAmount)}
                                    </h1>
                                </div>
                                <div className="col-span-2 md:block hidden w-full overflow-scroll hide-scrollbar justify-between fiat-balances">
                                    {navbarItems.map(item => (
                                        <Button
                                            onClick={() => openModalAction(item.type, balance)}
                                            data-e2e={`${item.type} ${getCurrencySymbol(balance.currencyShortName)}`}
                                            className="py-2 px-3 h-full mr-2 rounded-xl text-sm font-medium"
                                            key={item.type}>
                                            {item.type}
                                        </Button>
                                    ))}
                                    <Button
                                        className="h-full mr-2 fiat-balance-freez-btn"
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${Freeze}`}
                                                alt="freeze"
                                                className="w-16px h-16px inline"
                                            />
                                        }></Button>
                                </div>
                                <div className="col-span-2 flex justify-end md:hidden">
                                    <MoreOutlined
                                        className="text-xl"
                                        onClick={() => {
                                            setSelectedCurrency(balance);
                                            setActionTypeModal(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <Divider className="my-2" />
                        </>
                    ))
                )}
            </div>
            <Drawer
                title={<DrawerHeader />}
                closable={false}
                open={actionTypeModal}
                destroyOnClose={true}
                height="60vh"
                onClose={() => setActionTypeModal(false)}
                placement="bottom">
                <div className="transaction-actions">
                    {navbarItems.map((balance, index) => (
                        <button
                            className={`transaction-actions-btn`}
                            key={index}
                            onClick={() => {
                                openModalAction(balance.type, selectedCurrency);
                                // setActionTypeModal(false);
                            }}>
                            <div className="transaction-actions-icon">
                                <img src={`${imageBaseUrl}${balance.icon}`} alt="" />
                            </div>
                            <p className="transaction-actions-title" data-e2e={balance.type}>
                                {balance.type}
                            </p>
                        </button>
                    ))}
                </div>
            </Drawer>
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Withdrawal'}
                confirmTitle={'Withdrawal Confirmation'}
                initialTitle={'Withdrawal'}
                transactionType={'Withdrawal'}
                successProcess={'withdrawn'}
                handleClose={onModalClose}
                balancesData={balancesData}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Third-party payment'}
                confirmTitle={'Third-party Payment Confirmation'}
                initialTitle={'Third-party Payment'}
                transactionType={'Payment Out'}
                successProcess={'sent'}
                handleClose={onModalClose}
                balancesData={balancesData}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <TransferModal
                transferModal={isModalOpen && openModal === 'Transfer'}
                client={id}
                handleClose={onModalClose}
                balancesData={balancesData}
                selectedCurrencyProp={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <FXFiatModal
                client={id}
                open={isModalOpen && openModal === 'FX'}
                handleClose={onModalClose}
                selectedCurrency={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <DepositFiatModal
                client={id}
                open={isModalOpen && openModal === 'Deposit'}
                handleClose={onModalClose}
                selectedCurrency={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
        </div>
    );
};

export default BalanceDetailsTab;
