import { TOGGLE_SIDEBAR_COLLAPSE } from '../actions/actionTypes';

const initialState = {
    collapsed: true,
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR_COLLAPSE:
            return {
                ...state,
                collapsed: !state.collapsed,
            };
        default:
            return state;
    }
};

export default configReducer;
