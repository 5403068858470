import { Modal } from 'antd';
import { CopyButton } from '../../utils/common';
import { WarningIcon } from '../../utils/Images';
import { useSelector } from 'react-redux';
import ThemeButton from '../common/ThemeButton';

const CryptoAddressConfirmationModal = ({ open, onCancel, handleClose, handleConfirm, data }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <Modal
            width={422}
            title={null}
            open={open}
            closable={false}
            className="address-confirm"
            centered
            onCancel={onCancel}
            footer={
                <>
                    <hr className="mt-3" />
                    <div className="flex justify-center space-x-8 mt-6 mb-2">
                        <ThemeButton
                            type="primary"
                            style="rounded-full"
                            text="Cancel"
                            action={handleClose}
                        />
                        <ThemeButton
                            type="primary"
                            style="rounded-full"
                            text="Update"
                            action={() => {
                                handleConfirm();
                                onCancel();
                            }}
                        />
                    </div>
                </>
            }>
            <div className="mt-4 relative flex justify-center items-center">
                <img
                    src={`${imageBaseUrl}${WarningIcon}`}
                    alt="warningIcon"
                    className="w-16 h-16"
                />
            </div>
            <div className="mt-4">
                <div className="">
                    <p className="text-xl font-bold text-center">Are you sure?</p>
                </div>
                <p className="text-base text-center mt-3 text-[#3F3F46]">
                    Are you sure you want to change the wallet address?
                </p>
                <hr className="mt-6" />
            </div>
            <div className="mt-3 flex justify-between py-[10px] px-4 overflow-hidden">
                <p className="text-base font-semibold text-center">{data}</p>
                <CopyButton msg={data} />
            </div>
        </Modal>
    );
};

export default CryptoAddressConfirmationModal;
