import React, { useEffect, useState } from 'react';
import {
    Divider,
    Form,
    Table,
    Button,
    Input,
    Space,
    Badge,
    Tag,
    Select,
    Pagination,
    Spin,
} from 'antd';
import {
    SearchOutlined,
    PlusOutlined,
    RightOutlined,
    DownOutlined,
    UpOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import FilterDrawer from '../components/modals/FilterDrawer';
import Sidebar from '../components/layout/Sidebar';
import CustomPagination from '../components/layout/CustomPagination';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../components/modals/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, MobileFilter } from '../utils/Images';
import apiRequest from '../utils/api';
import useDebounce from '../utils/useDebounce';
import { updateFilters, resetFilters } from '../redux/actions/filtersActions';
import moment from 'moment';
import { FormDOB, FormInput, FormPassword, FormSelect } from '../components/forms';
import { feeProfileOptions, thresholdProfileOptions } from '../utils/constant';
import ThemeButton from '../components/common/ThemeButton';

const { Option } = Select;

const Accounts = () => {
    const [form] = Form.useForm();
    const [filterModal, setFilterModal] = useState(false);
    const [addAccountModal, setAddAccountModal] = useState(false);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('createdAt');
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [filterQuery, setFilterQuery] = useState([]);
    const [filterQueryRender, setFilterQueryRender] = useState([]);
    const [clearFilterDrawer, setClearFilterDrawer] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false);
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [accountFormData, setAccountFormData] = useState({
        accountNumber: null,
        active: null,
        address: null,
        clientEmail: null,
        companyName: null,
        confirmPassword: null,
        country: null,
        dob: null,
        name: null,
        password: null,
        phoneNumber: null,
        postCode: null,
        twoFA: null,
        whiteLabelId: null,
    });
    const [errorMsg, setErrorMsg] = useState({
        confirmPassword: null,
    });
    const [error, setError] = useState('');
    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const adminRole = localStorage.getItem('adminRole');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const filters = useSelector(state => state.filters);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetFilters());
        getWhiteLableList();
    }, []);

    useEffect(() => {
        getClients(sortBy, orderBy, pagination.current, filterQuery, searchText);
    }, [filterQuery]);

    const searchClients = useDebounce(value => {
        getClients(sortBy, orderBy, 1, filterQuery, value);
    }, 500);

    const getClients = async (
        sortBy = 'createdAt',
        orderBy = 'desc',
        page,
        filterQuery = [],
        searchText = '',
    ) => {
        let filterParams = {
            searchText,
            paymentProvider: paymentProvider,
            page: page,
            sortBy: sortBy,
            orderBy: orderBy,
            whiteLabelId: whiteLabelId,
            filterQuery,
        };

        const body = {
            email: email,
            filterParams: filterParams,
        };

        setLoading(true);
        try {
            const response = await apiRequest('/get-clients', 'POST', body);

            if (response.success || response.data) {
                setLoading(false);
                if (response.data) {
                    setData(response.data.clientsData);
                    setPagination(prev => ({
                        ...prev,
                        total: response.data.totalCount,
                        current: page,
                    }));
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getWhiteLableList = async () => {
        const body = {
            email: email,
            filterParams: {
                limit: 100,
            },
        };

        setLoading(true);
        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response.success || response.data) {
                setLoading(false);
                const options = response.data.whitelabelList.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const addClientAccount = async () => {
        try {
            const formValues = await form.validateFields();
            const body = {
                email: email,
                paymentProvider: paymentProvider,
                adminRole: adminRole,
                ...formValues,
            };

            setLoading(true);
            try {
                const response = await apiRequest('/create-client', 'POST', body);

                if (response.success || response.data) {
                    setLoading(false);
                    getClients(sortBy, orderBy, pagination.current, filterQuery, searchText);
                    handleAddAccountClose();
                } else {
                    setError(response.error);
                }
            } catch (error) {
                setError('Something went wrong');
                setLoading(false);
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const handleAddAccount = e => {
        const { value, name } = e.target;

        // if (name === 'confirmPassword') {
        //     validatePassword(name, value, accountFormData['password']);
        // }

        setAccountFormData({
            ...accountFormData,
            [name]: value,
        });
    };

    const handleSelectAddAccount = (value, name) => {
        setAccountFormData({
            ...accountFormData,
            [name]: value,
        });
    };

    const handleDateAddAccount = date => {
        const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');

        setAccountFormData({
            ...accountFormData,
            dob: formattedDate,
        });
    };

    const validatePassword = (name, confirmPassword, password) => {
        const passwordsMatch = password === confirmPassword;
        const errorMessage = passwordsMatch ? null : 'Passwords do not match!';

        setErrorMsg(prevData => ({
            ...prevData,
            [name]: errorMessage,
        }));
    };

    const handleAddAccountClose = () => {
        setAddAccountModal(false);
        setAccountFormData({});
        setErrorMsg({});
        setError('');
        form.resetFields();
    };

    const handleTableChange = (newPagination, sorter) => {
        const { current } = newPagination;
        const { field, order } = sorter;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        let sortBy = 'createdAt';
        let orderBy = 'desc';

        if (order === 'ascend') {
            sortBy = field;
            orderBy = 'asc';
        } else if (order === 'descend') {
            sortBy = field;
            orderBy = 'desc';
        }

        setSortBy(sortBy);
        setOrderBy(orderBy);

        getClients(sortBy, orderBy, current, filterQuery, searchText);
    };

    const renderTableData = (row, key) => {
        const emptyState = <div className="flex justify-center">-</div>;

        switch (key) {
            case 'createdAt':
                return row ? moment(row).format('DD/MM/YYYY - HH:mm') : emptyState;
            case 'paymentProvider':
                return row?.paymentProvider?.firstName
                    ? `${row.paymentProvider.firstName} ${row.paymentProvider?.lastName}`
                    : emptyState;
            default:
                return row ?? emptyState;
        }
    };

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            render: row => renderTableData(row, 'name'),
            key: 'name',
            sorter: true,
        },
        // Adding fields as per old admin-ui
        {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            render: row => renderTableData(row, 'accountNumber'),
            key: 'accountNumber',
            sorter: true,
        },
        {
            title: 'Payment Provider Name',
            render: row => renderTableData(row, 'paymentProvider'),
            key: 'paymentProvider',
            sorter: true,
        },
        // {
        //     title: 'White label Name',
        //     dataIndex: 'whiteLabelName',
        //     render: row => renderTableData(row, 'whiteLabelName'),
        //     key: 'whiteLabelName',
        //     sorter: true,
        // },
        {
            title: 'Account ID',
            dataIndex: 'clientId',
            render: row => renderTableData(row, 'clientId'),
            key: 'clientId',
            sorter: true,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: row => renderTableData(row, 'createdAt'),
            key: 'createdAt',
            sorter: true,
        },
        // End of fields as per old admin-ui

        {
            title: 'Email',
            dataIndex: 'clientEmail',
            render: row => renderTableData(row, 'clientEmail'),
            key: 'clientEmail',
            sorter: true,
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            render: row => renderTableData(row, 'phoneNumber'),
            key: 'phoneNumber',
            sorter: true,
        },
        {
            title: 'Account Status',
            dataIndex: 'active',
            key: 'active',
            render: status => (
                <span
                    className={`px-2 py-1 rounded-full ${
                        status ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600'
                    }`}>
                    {status ? 'Active' : 'Inactive'}
                </span>
            ),
            sorter: true,
        },

        //  This commented code is present in design but as we don't have all data for now we can use that code later

        // {
        //     title: "CA AML Status",
        //     dataIndex: "caAmlStatus",
        //     key: "caAmlStatus",
        //     render: (status) => (
        //         <span
        //             className={`px-2 py-1 rounded-full ${
        //                 status === "Completed"
        //                     ? "bg-green-100 text-green-600"
        //                     : "bg-red-100 text-red-600"
        //             }`}
        //         >
        //             {status}
        //         </span>
        //     ),
        //     sorter: (a, b) => a.age - b.age,
        // },
        // {
        //     title: "PEP Status",
        //     dataIndex: "pepStatus",
        //     key: "pepStatus",
        //     sorter: (a, b) => a.age - b.age,
        // },
        // {
        //     title: "Risk Level",
        //     dataIndex: "riskLevel",
        //     key: "riskLevel",
        //     sorter: (a, b) => a.age - b.age,
        // },
        // {
        //     title: 'Last Transaction',
        //     dataIndex: 'lastTransaction',
        //     key: 'lastTransaction',
        //     sorter: true,
        // },
        // {
        //     title: 'Last Login',
        //     dataIndex: 'lastLogin',
        //     key: 'lastLogin',
        //     sorter: true,
        // },
        {
            title: '',
            dataIndex: 'seeMore',
            key: 'seeMore',
            render: data => (
                <span className="flex items-center justify-between">
                    {data} <RightOutlined />
                </span>
            ),
        },
    ];

    // const AcccountsHeader = () => {

    //     return (

    //     );
    // };

    const ExpandedRow = ({ record }) => {
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {[
                    { label: 'Full Name', value: record.name },
                    { label: 'Account Number', value: record.accountNumber },
                    {
                        label: 'Payment Provider Name',
                        value: (
                            <span className={`px-2 py-1 rounded-full`}>
                                {record.paymentProvider?.firstName &&
                                    `${record.paymentProvider?.firstName} ${record.paymentProvider?.lastName}`}
                            </span>
                        ),
                    },
                    { label: 'White label Name', value: record.whitelableName },
                    { label: 'Account ID', value: record.clientId },
                    { label: 'Created Date', value: record.createdAt },
                    { label: 'Email', value: record.clientEmail },
                    { label: 'Phone', value: record.phoneNumber },
                    {
                        label: 'Account Status',
                        value: (
                            <span
                                className={`px-2 py-1 rounded-full ${
                                    record.active
                                        ? 'bg-green-100 text-green-600'
                                        : 'bg-gray-100 text-gray-600'
                                }`}>
                                {record.active ? 'Active' : 'Inactive'}
                            </span>
                        ),
                    },
                    // { label: 'Last Transaction', value: record.lastTransaction },
                    // { label: 'Last Login', value: record.lastLogin },
                ].map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
            </div>
        );
    };

    const handleExpandRow = id => {
        setExpandedRowKey(expandedRowKey === id ? null : id);
    };

    const handleQuery = async value => {
        const filterValue = [
            { type: 'name', value: value.name, label: 'Full Name' },
            { type: 'clientEmail', value: value.clientEmail, label: 'Email' },
            { type: 'phoneNumber', value: value.phoneNumber, label: 'Phone Number' },
            { type: 'clientId', value: value.clientId, label: 'Client ID' },
            { type: 'active', value: value.active, label: 'Account Status' },
        ].filter(entry => entry.value && entry.value.length > 0);

        let queryValue = [...filterValue];

        if (value.active !== '') {
            if (value.active === 'all') {
                queryValue = queryValue.filter(entry => entry.type !== 'active');
            }
        }

        setFilterQueryRender(filterValue);
        setFilterQuery(queryValue);
    };

    const handleTagClose = removedTag => {
        const removedQuery = filterQuery.filter(tag => tag !== removedTag);
        const removedQueryRender = filterQueryRender.filter(tag => tag !== removedTag);

        const updatedFilters = { ...filters, [removedTag.type]: [] };
        dispatch(updateFilters(updatedFilters));

        setFilterQuery(removedQuery);
        setFilterQueryRender(removedQueryRender);
    };

    const clearQueryRender = () => {
        setFilterQuery([]);
        setFilterQueryRender([]);
        setClearFilterDrawer(true);
    };

    const FilterQuerySection = ({ color }) => {
        return (
            <div className="filter-tags">
                {filterQueryRender.map((filter, key) => (
                    <Tag
                        bordered={false}
                        closable
                        key={key}
                        className={`text-sm font-medium ${color && '!bg-white'}`}
                        onClose={() => handleTagClose(filter)}>
                        <span>
                            {filter.label}:
                            {filter.type === 'active'
                                ? filter.value === 'true'
                                    ? ' Active'
                                    : filter.value === 'false'
                                      ? ' Inactive'
                                      : ' All'
                                : ' ' + filter.value}
                        </span>
                    </Tag>
                ))}

                {filterQueryRender.length > 0 && (
                    <div
                        className="text-[#1570EF] text-sm font-medium cursor-pointer pl-[7px]"
                        onClick={clearQueryRender}>
                        Reset all ({filterQueryRender.length})
                    </div>
                )}
            </div>
        );
    };

    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getClients(sortBy, orderBy, page, filterQuery);
    };

    const handleSelectChange = (fieldName, value) => {
        form.setFieldsValue({ [fieldName]: value });
    };

    return (
        <>
            <Sidebar>
                <div className="py-[16px]">
                    <div className="lg:px-[24px] px-0">
                        {/* Desktop Screen */}
                        <div className="desktop-page-block hidden lg:flex flex-col gap-[24px] p-6 bg-white rounded-t-xl">
                            <div className="flex items-center justify-between">
                                <p className="page-title">Accounts</p>
                                <Space>
                                    <Input
                                        placeholder="Search accounts"
                                        data-e2e="search-bar"
                                        suffix={<SearchOutlined />}
                                        className="w-[460px] p-2"
                                        name="searchText"
                                        value={searchText}
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                            searchClients(e.target.value);
                                        }}
                                        allowClear
                                    />

                                    <ThemeButton
                                        type="primary"
                                        text="Add account"
                                        icon={<PlusOutlined />}
                                        dataE2e="add-account"
                                        action={() => setAddAccountModal(true)}
                                    />
                                    <Badge count={filterQueryRender.length} className="">
                                        <ThemeButton
                                            icon={
                                                <img
                                                    src={`${imageBaseUrl}${Filter}`}
                                                    alt=""
                                                    data-e2e="filters-icon"
                                                    className="w-3 h-3 inline cursor-pointer"
                                                />
                                            }
                                            // className="py-2 px-3 h-auto"
                                            text="Filters"
                                            action={() => setFilterModal(true)}
                                        />
                                    </Badge>
                                </Space>
                            </div>
                            <div className="hidden lg:flex items-center gap-x-[8px] gap-y-[12px]">
                                <FilterQuerySection />
                            </div>
                        </div>
                        {/* Tablet & Mobile Screen */}
                        <div className="flex flex-col">
                            <div className="lg:hidden flex justify-between items-center px-3">
                                {enableSearch ? (
                                    <Input
                                        placeholder="Search accounts"
                                        prefix={
                                            <LeftOutlined onClick={() => setEnableSearch(false)} />
                                        }
                                        suffix={<SearchOutlined />}
                                        className="sm:!w-[351px] md:!w-[696px] p-2 w-full"
                                        name="searchText"
                                        value={searchText}
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                            searchClients(e.target.value);
                                        }}
                                        allowClear
                                    />
                                ) : (
                                    <>
                                        <h2 className="text-base font-semibold">Accounts List</h2>
                                        <div className="flex items-center gap-5">
                                            <SearchOutlined
                                                className="cursor-pointer"
                                                onClick={setEnableSearch}
                                            />
                                            <img
                                                src={`${imageBaseUrl}${MobileFilter}`}
                                                className="w-4 h-3 cursor-pointer"
                                                onClick={() => setFilterModal(true)}
                                                alt=""
                                            />
                                            <PlusOutlined
                                                className="py-2 px-3 h-auto bg-primary text-white rounded-lg cursor-pointer"
                                                onClick={() => setAddAccountModal(true)}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="lg:hidden flex items-center gap-x-[8px] gap-y-[12px] py-[12px]">
                                <FilterQuerySection color="white" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:block hidden bg-[#F7F7F7] h-full xl:h-[900px] w-full accounts-page lg:px-6">
                        <div className="bg-white rounded-lg">
                            <Table
                                dataSource={data}
                                columns={columns}
                                className="cursor-pointer rounded-b-lg custom-table"
                                rowKey={record => record?._id}
                                onRow={record => {
                                    return {
                                        onClick: () => navigate(`/accounts/${record.clientId}`),
                                    };
                                }}
                                loading={loading}
                                pagination={{
                                    position: ['bottomCenter'],
                                    itemRender: CustomPagination,
                                    showSizeChanger: false,
                                    current: pagination.current,
                                    total: pagination.total,
                                }}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                    <div className="block lg:hidden">
                        {!loading ? (
                            <>
                                {data.length > 0 &&
                                    data.map((item, key) => {
                                        const isExpanded = expandedRowKey === item._id;
                                        return (
                                            <div
                                                key={key}
                                                className="bg-white border-b border-[#E4E4E7]">
                                                <div className="flex justify-between items-center p-3 cursor-pointer">
                                                    <div
                                                        className="flex gap-2 items-center"
                                                        onClick={() =>
                                                            navigate(`/accounts/${item.clientId}`)
                                                        }>
                                                        <span className="bg-[#12B76A] inline-block h-2 w-2 rounded-full"></span>
                                                        <div>
                                                            <h1 className="text-sm font-medium pb-1">
                                                                {item.name}
                                                            </h1>
                                                            <p className="font-normal text-xs text-darkGray">
                                                                {item.clientEmail}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-4 items-center">
                                                        {/* <div className="text-end">
                                                <h1 className="text-sm font-medium pb-1">
                                                    {item.riskLevel}
                                                </h1>
                                                <p className="font-normal text-xs text-darkGray">
                                                    {item.lastTransaction}
                                                </p>
                                            </div> */}
                                                        <div
                                                            className=""
                                                            onClick={() =>
                                                                handleExpandRow(item._id)
                                                            }>
                                                            {isExpanded ? (
                                                                <UpOutlined
                                                                    width={10}
                                                                    height={10}
                                                                />
                                                            ) : (
                                                                <DownOutlined
                                                                    width={10}
                                                                    height={10}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {isExpanded && (
                                                    <div className="p-3">
                                                        <ExpandedRow record={item} />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                <Pagination
                                    total={pagination.total}
                                    className="py-3 flex justify-center bg-[#F5F5F5]"
                                    current={pagination.current}
                                    onChange={handlePagination}
                                />
                            </>
                        ) : (
                            <div className="flex items-center justify-center h-[50vh] ">
                                <Spin />
                            </div>
                        )}
                    </div>
                </div>
                <FilterDrawer
                    open={filterModal}
                    setOpen={setFilterModal}
                    getQuery={handleQuery}
                    clearFilterDrawer={clearFilterDrawer}
                    setClearFilterDrawer={setClearFilterDrawer}
                />
                <CommonModal
                    title="Add Account"
                    open={addAccountModal}
                    handleClose={handleAddAccountClose}
                    className={'common-mobile-view account-model-view'}
                    footerText="Send invite"
                    error={error}
                    onFinish={addClientAccount}
                    form={form}>
                    <Form
                        form={form}
                        layout="horizontal"
                        colon={false}
                        className="lg:mt-[20px] h-[75vh] overflow-auto hide-scrollbar px-1 modal-form">
                        <FormInput
                            label="Account Number:"
                            name="accountNumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Account Number is required',
                                },
                                {
                                    pattern: /^[A-Za-z0-9]*$/,
                                    message:
                                        'Account Number must contain only Latin letters and numbers',
                                },
                            ]}
                            placeholder="Enter Account Number"
                            dataE2e="enter-account-number"
                            defaultValue={accountFormData.accountNumber}
                        />

                        <FormInput
                            label="Name:"
                            name="name"
                            placeholder="Enter Name"
                            dataE2e="enter-name"
                            rules={[
                                {
                                    pattern: /^[A-Za-z\s]*$/,
                                    message: 'Name must contain only Latin letters and spaces',
                                },
                            ]}
                        />
                        <FormDOB
                            label="Birthday:"
                            name="dob"
                            placeholder="dd/mm/yyy"
                            format="DD/MM/YYYY"
                            onChange={handleDateAddAccount}
                        />
                        <FormInput
                            label="Phone Number:"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            dataE2e="enter-phone-number"
                            rules={[
                                {
                                    pattern: /^[0-9\s\-()+]*$/,
                                    message: 'Phone Number can contain only numbers and "+" sign',
                                },
                            ]}
                        />
                        <FormInput
                            label="Address:"
                            name="address"
                            placeholder="Enter Address"
                            dataE2e="enter-address"
                        />
                        <FormInput
                            label="City:"
                            name="city"
                            placeholder="Enter City"
                            dataE2e="enter-city"
                            rules={[
                                {
                                    pattern: /^[A-Za-z\s]*$/,
                                    message: 'City must contain only Latin letters and spaces',
                                },
                            ]}
                        />
                        <FormInput
                            label="Country:"
                            name="country"
                            placeholder="Enter Country"
                            dataE2e="enter-country"
                            rules={[
                                {
                                    pattern: /^[A-Za-z\s]*$/,
                                    message: 'Country must contain only Latin letters and spaces',
                                },
                            ]}
                        />
                        <FormInput
                            label="Post Code:"
                            name="postCode"
                            placeholder="Enter Post Code"
                            dataE2e="enter-post-code"
                        />
                        <FormInput
                            label="Email:"
                            name="clientEmail"
                            rules={[
                                {
                                    required: true,
                                    message: 'Client Email is required',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address!',
                                },
                            ]}
                            placeholder="Enter Email"
                            dataE2e="enter-email"
                            type="email"
                        />
                        <FormPassword
                            label="Password:"
                            name="password"
                            rules={[{ required: true, message: 'Please enter a password' }]}
                            placeholder="Create Password"
                            dataE2e="create-password"
                        />
                        <FormPassword
                            label="Confirm Password:"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            dataE2e="confirm-password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords do not match'),
                                        );
                                    },
                                }),
                            ]}
                        />
                        <FormSelect
                            label="Fee Profile:"
                            name="feeProfile"
                            defaultValue={'Standard'}
                            placeholder="Select feeProfile"
                            options={feeProfileOptions}
                            onChange={val => handleSelectChange('feeProfile', val)}
                        />
                        <FormSelect
                            label="Threshold Profile:"
                            name="thresholdProfile"
                            defaultValue={'Low'}
                            placeholder="Select thresholdProfile"
                            options={thresholdProfileOptions}
                            onChange={val => handleSelectChange('thresholdProfile', val)}
                        />
                        <FormSelect
                            label="Whitelabel:"
                            name="whiteLabelId"
                            placeholder="Select Whitelabel"
                            options={whiteLabelData.map((item, key) => ({
                                value: item.value,
                                label: item.label,
                                key: key,
                            }))}
                            onChange={val => handleSelectChange('whiteLabelId', val)}
                        />
                        <FormSelect
                            label="2FA:"
                            name="twoFA"
                            placeholder="Select 2FA"
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            onChange={val => handleSelectChange('twoFA', val)}
                        />
                        <FormSelect
                            label="Status:"
                            name="active"
                            placeholder="Select Status"
                            options={[
                                { value: true, label: 'Active' },
                                { value: false, label: 'Inactive' },
                            ]}
                            onChange={val => handleSelectChange('active', val)}
                        />
                    </Form>
                </CommonModal>
            </Sidebar>
        </>
    );
};

export default Accounts;
