import React, { useState } from 'react';
import LinkedMerchants from './LinkedMerchants';
import MerchantAccount from './MerchantAccount';

const MerchantsTab = () => {
    const [tableMode, setTableMode] = useState('linkedMerchants');

    return (
        <div className="lg:pt-8 px-3 lg:px-0 bg-white pt-4">
            <div className="p-1 rounded-lg bg-[#F4F4F5] md:w-max  cursor-pointer flex gap-2 items-center">
                <p
                    onClick={() => {
                        setTableMode('linkedMerchants');
                    }}
                    data-e2e="linked-merchants"
                    className={`text-base font-medium w-1/2 md:w-auto text-center py-[6px] px-4 ${tableMode === 'linkedMerchants' ? 'bg-white rounded-md text-[#18181B]' : 'text-[#51525C]'}`}>
                    Linked Merchants
                </p>
                <p
                    onClick={() => {
                        setTableMode('merchantAccounts');
                    }}
                    data-e2e="merchant-account"
                    className={`text-base font-medium w-1/2 md:w-auto text-center py-[6px] px-4 ${tableMode === 'merchantAccounts' ? 'bg-white rounded-md text-[#18181B]' : 'text-[#51525C]'}`}>
                    Merchant Account
                </p>
            </div>
            {tableMode === 'linkedMerchants' ? <LinkedMerchants /> : <MerchantAccount />}
        </div>
    );
};

export default MerchantsTab;
