import React from 'react';
import { Divider } from 'antd';
import Sidebar from '../components/layout/Sidebar';

const Withdrawals = () => {
    return (
        <>
            <Sidebar>
                <div className="bg-[#F7F7F7] h-full xl:h-[2268px] w-full">
                    <Divider style={{ margin: 0 }} />
                </div>
            </Sidebar>
        </>
    );
};

export default Withdrawals;
