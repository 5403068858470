import axios from 'axios';
import { store } from '../index';
import { Modal } from 'antd';

let alertDisplayed = false;
const showSessionExpiredModal = () => {
    Modal.info({
        title: <p className="text-primary ">Session Expired</p>,
        content: (
            <div className="my-5">
                <p>Your session has expired. Please log in again.</p>
            </div>
        ),
        onOk() {
            localStorage.clear();
            window.location.href = '/';
        },
        centered: true,
        okButtonProps: { className: 'bg-primary' },
    });
};

/**
 * Makes an API request.
 *
 * @param {string} endpoint - The endpoint to which the request is made.
 * @param {string} [method='GET'] - The HTTP method to use for the request. Defaults to 'GET'.
 * @param {Object} [body=null] - The body to send with the request. Defaults to null.
 * @param {Object} [headers={}] - Additional headers to include with the request. Defaults to an empty object.
 * @returns {Promise<Object>} [ Success ] - {  success: true, data: api response data  }.
 * @returns {Promise<Object>} [ Failed ] - {  success: false, error:"some error message"  }.
 */

const apiRequest = async (endpoint, method = 'GET', data = null, headers = {}) => {
    const state = store.getState();
    const API_BASE_URL = state.config.api_url;

    const url = `${API_BASE_URL}/restapi${endpoint}`;

    if (endpoint != '/crypto-exchange-rate') {
        data = {
            ...data,
            adminRole: localStorage.getItem('adminRole') ? localStorage.getItem('adminRole') : null,
        };
    }
    const options = {
        method,
        url,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        data,
        withCredentials: true,
    };

    try {
        const response = await axios(options);
        return { success: true, data: response.data };
    } catch (error) {
        if (error.response && error.response.status === 403) {
            if (!alertDisplayed) {
                showSessionExpiredModal();
                alertDisplayed = true;
            }
        } else if (error.response) {
            console.error('API call error response:', error.response.data);
            return {
                success: false,
                error:
                    error.response?.data?.msg ||
                    error?.response?.data?.err ||
                    'Something went wrong',
            };
        } else if (error.request) {
            console.error('API call no response:', error.request);
            return { success: false, error: 'No response received from server' };
        } else {
            console.error('API call setup error:', error.message);
            return { success: false, error: error.message };
        }
    }
};

export default apiRequest;
