import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { CryptoSelectModal } from './CryptoSelectModal';
import {
    getCurrencySymbol,
    removeCommas,
    toFixedTrunc,
    useWindowWidth,
    validateAmount,
} from '../../utils/common';
import { useSelector } from 'react-redux';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    LoadingOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import TransactionResultModal from './TransactionResultModal';

export const ExchangeCryptoModal = ({ open, handleClose, onSubmit }) => {
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [amountError, setAmountError] = useState('');
    const [exchangeRate, setExchangeRate] = useState(1);
    const { clientData } = useSelector(state => state.account);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const windowWidth = useWindowWidth();
    const email = localStorage.getItem('email');
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        fromCryptoAmount: null,
        toCryptoAmount: null,
        fromCryptoId: 'BTC',
        toCryptoId: 'ETH',
    });

    const fromCryptoIcon = balancesCrypto.find(
        x => x.currencyShortName === formData.fromCryptoId,
    ).currencyIcon;

    const toCryptoIcon = balancesCrypto.find(
        x => x.currencyShortName === formData.toCryptoId,
    ).currencyIcon;

    const handleAmountChange = e => {
        if (exchangeRate) {
            let fromCryptoAmount, toCryptoAmount, amountError;
            const name = e.target.name;
            const value = e.target.value;

            if (name === 'fromCryptoAmount') {
                const fixedRate = 1 / exchangeRate;
                toCryptoAmount = fixedRate && removeCommas(value) * fixedRate;
                toCryptoAmount = toFixedTrunc(toCryptoAmount, 2);
                fromCryptoAmount = value;
                amountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoAmount,
                    balancesCrypto,
                );
            } else if (name === 'toCryptoAmount') {
                fromCryptoAmount = exchangeRate && removeCommas(value) * exchangeRate;
                fromCryptoAmount = toFixedTrunc(fromCryptoAmount, 2);
                toCryptoAmount = value;
                amountError = validateAmount(value, 0, formData.fromCryptoAmount, balancesCrypto);
            }
            setFormData({ ...formData, fromCryptoAmount, toCryptoAmount });
            setAmountError(amountError);
        }
    };

    const onCancel = () => {
        handleClose();
        setStep(1);
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'BTC',
            toCryptoId: 'ETH',
        });
    };

    const createTransaction = async () => {
        const blockchain = balancesCrypto.find(
            crypto => crypto.currencyShortName === formData.toCryptoId,
        )?.blockchain;
        try {
            const body = {
                adminRole: 'PaymentProviderAdmin',
                email,
                currencyType: 'crypto',
                type: 'Exchange',
                clientId: clientData?.clientId,
                transactionEmail: localStorage.getItem('email'),
                transactionDetails: {
                    ...formData,
                    blockchain,
                    fxrate: 0.8386378,
                    // "transactionFee": "0.83"
                },
            };

            setLoading(true);
            const response = await onSubmit(body);
            onCancel();
            setStep(3);
            if (!response.success) {
                setError(response?.error);
            }

            setLoading(false);
        } catch (error) {
            onCancel();
            console.error('Error while creating transaction:', error);
        }
    };

    return (
        <>
            <Modal
                open={open}
                destroyOnClose
                className="transaction-modal"
                title={
                    step === 1 ? (
                        <p className="text-2xl font-semibold relative flex">Exchange</p>
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => setStep(1)}
                            />
                            <p className="text-2xl font-semibold relative flex">
                                Exchange Confirmation
                            </p>
                        </div>
                    ) : null
                }
                onCancel={onCancel}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="primary"
                                loading={step === 2 && loading}
                                onClick={async () => {
                                    try {
                                        if (step === 1) {
                                            setStep(2);
                                        } else {
                                            createTransaction();
                                        }
                                    } catch (error) {
                                        console.error('Validation failed:', error);
                                    }
                                }}
                                className="rounded-full px-8 py-3 w-full sm:w-auto h-[46px]"
                                data-e2e={
                                    step === 1
                                        ? 'Continue'
                                        : `${loading ? 'Processing' : 'Exchange Now'}`
                                }>
                                {step === 1
                                    ? 'Continue'
                                    : `${loading ? 'Processing' : 'Exchange Now'}`}
                            </Button>
                        </div>
                    )
                }
                handleClose={onCancel}>
                {step === 1 ? (
                    <>
                        <div className="mt-12 flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">From:</label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name="fromCryptoAmount"
                                    data-e2e="from-field"
                                    onChange={handleAmountChange}
                                    value={formData.fromCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[16px] pl-6"
                                />
                                <CryptoSelectModal
                                    value={formData.fromCryptoId}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            fromCryptoId: e,
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="my-5 flex items-center">
                            <div className="flex-1 border-b border-[#E4E4E7]"></div>
                            <div className="w-10 h-10 flex-shrink-0 rounded-full flex border border-[#D1D1D6] flex-col justify-center items-center">
                                <CaretUpOutlined className="-mb-0.5" />
                                <CaretDownOutlined className="-mt-0.5" />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">To:</label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    name="toCryptoAmount"
                                    type="text"
                                    data-e2e="to-text-field"
                                    onChange={handleAmountChange}
                                    value={formData.toCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[16px] pl-6"
                                />
                                <CryptoSelectModal
                                    value={formData.toCryptoId}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            toCryptoId: e,
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">Rate:</label>
                            <div className="text-base font-normal">
                                <span>1 USDT = 1 USD</span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">Fee:</label>
                            <span className="text-base font-normal">0.2%</span>
                        </div>
                        <div className=" flex justify-between items-center mb-12">
                            <label className="text-base font-normal text-start">Total:</label>
                            <span>₹ 202 (USDT)</span>
                        </div>
                        {amountError && (
                            <div className="jncAlert alert alert-danger" role="alert">
                                {amountError}
                            </div>
                        )}
                    </>
                ) : (
                    step === 2 && (
                        <div className="my-12 border rounded-lg p-3">
                            <h1 className="text-base font-semibold">Transaction information:</h1>
                            <div className="mt-5">
                                <p className="text-sm font-normal text-[#51525C] ">From:</p>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${fromCryptoIcon}`}
                                            width={24}
                                            height={24}
                                            alt="cryptoIcon"
                                            className=""
                                        />
                                        <span className="text-xl font-normal text-[#26272B]">
                                            {formData.fromCryptoId}
                                        </span>
                                    </div>
                                    <p className="text-xl font-semibold text-[#26272B] ">
                                        {formData.fromCryptoAmount} ({formData.fromCryptoId})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <p className="text-sm font-normal text-[#51525C] ">TO:</p>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${toCryptoIcon}`}
                                            width={24}
                                            height={24}
                                            alt="cryptoIcon"
                                            className=""
                                        />
                                        <span className="text-xl font-normal text-[#26272B]">
                                            {formData.toCryptoId}
                                        </span>
                                    </div>
                                    <p className="text-xl font-semibold text-[#26272B]">
                                        {formData.toCryptoAmount} ({formData.toCryptoId})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Rate:</p>
                                <p className="text-base font-medium text-[#18181B] ">
                                    1USD = 1USDT
                                </p>
                            </div>
                            <div className="mt-3 flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Fee:</p>
                                <p className="text-base font-medium text-[#18181B]">1USD = 1USDT</p>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Total:</p>
                                <p className="text-base font-medium text-[#18181B]">302 (USDT)</p>
                            </div>
                        </div>
                    )
                )}
            </Modal>
            <TransactionResultModal
                type={error ? error : 'success'}
                open={step === 3}
                handleClick={onCancel}
                title={error ? 'Error!' : 'Exchange Confirmed!'}
                message={error ? error : 'Has been deposited into your Juno Money wallet.'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {getCurrencySymbol(formData.toCryptoId)} {100 || 0} (
                            {formData.toCryptoId})
                        </p>
                    </>
                )}
            </TransactionResultModal>
        </>
    );
};
