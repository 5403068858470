import React from 'react';
import { Freeze } from '../../utils/Images';
import { useSelector } from 'react-redux';
import ThemeButton from '../common/ThemeButton';
import CryptoWalletItem from './CryptoWalletItem';

const CryptoWalletsTab = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientWalletsBalances = useSelector(state => state.account.cryptoBalancesData);

    return (
        <div className="lg:pt-8 bg-white">
            <div className="crypto-wallets mb-[24px]">
                <h1 className="text-lg font-semibold wallet-heading">
                    The accounts wallet balances
                </h1>

                <ThemeButton
                    text="Freeze all"
                    style="mr-3"
                    autoWidth={true}
                    icon={<img src={`${imageBaseUrl}${Freeze}`} alt="freeze" className="w-4 h-4" />}
                />
            </div>
            {clientWalletsBalances.map(wallet => (
                <CryptoWalletItem key={wallet.currencyShortName} wallet={wallet} />
            ))}
        </div>
    );
};

export default CryptoWalletsTab;
