import React from 'react';
import { Form, Input } from 'antd';

const FormInput = ({
    label,
    name,
    type,
    initialValue,
    value,
    defaultValue,
    placeholder,
    required,
    disabled,
    rules,
    help,
    readOnly,
    className,
    formClassName,
    dataE2e,
}) => (
    <Form.Item
        label={
            <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                {label}
            </p>
        }
        initialValue={initialValue}
        name={name}
        required={required}
        rules={rules}
        help={help}
        className={`mt-[30px] ${formClassName}`}>
        <div className="relative w-full sm:w-[320px] mr-auto">
            <Input
                type={type}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                data-e2e={dataE2e}
                className={`w-full lg:w-[320px] h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px]  ${className}`}
            />
        </div>
    </Form.Item>
);

export default FormInput;
