import React from 'react';
import { Form, Input } from 'antd';

const FormPassword = ({
    label,
    name,
    placeholder,
    required,
    rules,
    help,
    onChange,
    className,
    formClassName,
    dataE2e,
    errorMsg,
}) => (
    <Form.Item
        label={
            <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                {label}
            </p>
        }
        name={name}
        required={required}
        rules={rules}
        validateTrigger={['onChange', 'onBlur']}
        className={`mt-8 ${formClassName}`}>
        <div className="relative w-full sm:w-[320px] mr-auto">
            <Input.Password
                placeholder={placeholder}
                name={name}
                data-e2e={dataE2e}
                onChange={onChange}
                className={`w-full h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px] ${className}`}
                autoComplete="new-password"
                allowClear
            />
        </div>
    </Form.Item>
);

export default FormPassword;
