import React, { useEffect, useState } from 'react';
import { Freeze } from '../../utils/Images';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import ThemeButton from '../common/ThemeButton';

const CryptoBalancesTab = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'Currency',
            dataIndex: 'currencyType',
            key: 'currencyType',
        },
        {
            title: 'USD',
            dataIndex: 'currency',
            key: 'currency',
            render: data => data || 0,
        },
        {
            title: 'Crypto Balances',
            dataIndex: 'cryptoBalances',
            key: 'cryptoBalances',
            render: data => data || 0,
        },
    ];
    const dataSource = [
        {
            key: '1',
            currencyType: 'Bitcoin (BTC)',
            currency: '$2458.0',
            cryptoBalances: '23456 BTC',
        },
        {
            key: '2',
            currencyType: 'Ethereum (ETH)',
            currency: '$2458.0',
            cryptoBalances: '23456 ETH',
        },
    ];

    return (
        <div className="lg:pt-8 bg-white">
            <div className="flex items-center justify-between pt-4 lg:p-0 lg:px-0">
                <h1 className="text-lg font-semibold wallet-heading">
                    The Accounts displayed balances
                </h1>
                <ThemeButton
                    text="Freeze all"
                    style="mr-3"
                    autoWidth={true}
                    icon={<img src={`${imageBaseUrl}${Freeze}`} alt="freeze" className="w-4 h-4" />}
                />
            </div>
            <div className="mt-6">
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            </div>
        </div>
    );
};

export default CryptoBalancesTab;
