import React from 'react';
import { transactionStatus } from '../utils/constant';
import { Tooltip } from 'antd';
import { formatAmount } from '../utils/formatAmount';
import { useNavigate } from 'react-router-dom';
import { LoadingSkeleton } from './common/Loading';
import { toFixedTrunc } from '../utils/common';

const StatusSummaryCard = ({ title, data, loading, dateFilter }) => {
    const navigate = useNavigate();

    const filteredTransactionStatus = transactionStatus.filter(status => {
        if (title?.label === 'Deposits' || title?.label === 'FX' || title?.label === 'Transfers') {
            return status.value !== 'rejected';
        }
        return true;
    });

    const handleStatusCountCode = (title, code) => {
        navigate('/transactions', {
            state: { transactionType: [title?.value], transactionStatus: code.value, dateFilter },
        });
    };

    if (loading) {
        return <LoadingSkeleton loading={loading} />;
    }

    return (
        <div className="rounded-xl border border-[#E4E4E7] overflow-hidden">
            <div className="px-6 py-3.5 border-b border-[#D1D1D6] bg-white">
                <span className="text-primary text-base font-semibold">{title?.label}</span>
            </div>
            {filteredTransactionStatus.map((status, key) => (
                <div
                    key={key}
                    className={`px-6 py-2.5 flex items-center ${key < transactionStatus.length - 1 ? 'border-b border-[#E4E4E7]' : ''} ${key % 2 === 0 ? 'bg-white' : 'bg-[#FAFAFA]'}`}>
                    <span className="flex-1 text-primary">{status.label}:</span>
                    <span>
                        <Tooltip
                            title={`$ ${toFixedTrunc((data && data[status.value]?.totalUSDAmount) || 0)}`}>
                            <span className="text-[#70707B]">
                                $ {formatAmount((data && data[status.value]?.totalUSDAmount) || 0)}{' '}
                                -{' '}
                            </span>
                        </Tooltip>
                        {data && (
                            <span
                                className={`text-[#1570EF] font-medium ml-1 hover:underline ${data[status.value]?.count ? 'cursor-pointer' : 'cursor-default'}`}
                                onClick={() =>
                                    data[status.value]?.count &&
                                    handleStatusCountCode(title, status)
                                }>
                                {data[status.value]?.count || 'N/A'}
                            </span>
                        )}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default StatusSummaryCard;
