import React from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { SuccessTick, Error } from '../../utils/Images';

const TransactionResultModal = ({ type, open, title, message, handleClick, children }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    return (
        <div>
            <Modal
                open={open}
                width={'422px'}
                height={'330px'}
                destroyOnClose
                centered
                footer={false}
                className="transaction-result"
                closable={false}>
                <div
                    className={`flex flex-col gap-4 items-center pt-12 border-t-4 rounded-t-md ${type === 'success' ? 'border-t-green-500' : 'border-t-[#F04438]'} `}>
                    <img
                        className=""
                        src={`${imageBaseUrl}${type === 'success' ? SuccessTick : Error}`}
                        alt="icon"
                    />

                    <p className="font-semibold text-xl">{title}</p>
                    <p className="text-xl font-medium">{children}</p>
                    <p
                        className=" text-center text-gray-600 text-base mb-6"
                        data-e2e="error"
                        data-e2e-message={message}>
                        {message}
                    </p>
                </div>
                <hr />
                <div className="flex justify-center rounded-b-3xl bg-slate-100 p-6">
                    <Button
                        onClick={handleClick}
                        data-e2e={type === 'success' ? 'Close' : 'Retry'}
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl text-white font-semibold ${type === 'success' ? 'bg-green-500' : 'bg-[#F04438]'}  text-sm`}>
                        {type === 'success' ? 'Close' : 'Retry'}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default TransactionResultModal;
