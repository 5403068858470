import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../modals/CommonModal';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import { transactionTypes } from '../../utils/constant';
import { FormInput, FormSelect } from '../forms';
import { PlusOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';

const FeeTab = () => {
    const [form] = Form.useForm();
    const [openFeeModal, setOpenFeeModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const params = useParams();
    const email = localStorage.getItem('email');

    const columns = [
        {
            title: 'Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
        },
        {
            title: '% fee',
            dataIndex: 'percentFee',
            key: 'percentFee',
            render: data => data || 0,
        },
        {
            title: 'Flat Fee',
            dataIndex: 'flatFee',
            key: 'flatFee',
            render: data => data || 0,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: row => (
                <>
                    <Button
                        className="border-0"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(row)}></Button>
                    <Button
                        className="border-0"
                        onClick={() => handleDeleteTransactionFee(row.transactionType)}
                        icon={<DeleteOutlined />}></Button>
                </>
            ),
        },
    ];

    const fetchClientsFee = async () => {
        const body = {
            email: email,
            clientId: params.id,
        };

        try {
            const response = await apiRequest('/get-client-fee-setting', 'POST', body);

            if (response.success && response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClientsFee();
    }, [params.id]);

    const handleClose = () => {
        setOpenFeeModal(false);
        setEditModal(false);
        setFormData({});
        form.resetFields();
        setErrorMsg('');
    };

    const handleEdit = row => {
        setEditModal(true);
        setOpenFeeModal(true);
        setFormData({
            transactionType: row.transactionType || '',
            percentFee: row.percentFee ?? 0,
            flatFee: row.flatFee ?? 0,
        });
        form.setFieldsValue({
            transactionType: row.transactionType || '',
            percentFee: row.percentFee ?? 0,
            flatFee: row.flatFee ?? 0,
        });
    };

    const handleApiCall = async payload => {
        try {
            const response = await apiRequest('/save-client-fee-setting', 'POST', payload);
            if (response.success) {
                fetchClientsFee();
                handleClose();
            } else {
                setErrorMsg(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createTransactionFee = async () => {
        const formValues = await form.validateFields();

        const payload = {
            clientId: params.id,
            email,
            status: editModal ? 'update' : 'create',
            transactionType: formValues.transactionType,
            percentFee: formValues.percentFee,
            flatFee: formValues.flatFee,
        };
        await handleApiCall(payload);
    };

    const handleDeleteTransactionFee = async type => {
        const payload = {
            clientId: params.id,
            email,
            transactionType: type,
            status: 'delete',
        };
        await handleApiCall(payload);
    };

    const handleSelectChange = value => {
        setFormData({ ...formData, transactionType: value });
        form.setFieldsValue({ transactionType: value });
        form.validateFields(['transactionType']);
    };

    return (
        <div className="lg:pt-8 bg-white">
            <div className="flex items-center justify-between pt-4 lg:p-0 px-6 lg:px-0">
                <h1 className="lg:text-lg text-base font-semibold">Fee&apos;s</h1>
                <div className="font-medium text-sm">
                    <ThemeButton
                        type="primary"
                        style="mr-3"
                        icon={<PlusOutlined />}
                        action={() => setOpenFeeModal(true)}
                        text="Add Fee"
                    />
                </div>
            </div>
            <div className="mt-6">
                <Table dataSource={data} columns={columns} pagination={false} />
            </div>
            <CommonModal
                title={editModal ? 'Edit Fee' : 'Add Fee'}
                open={openFeeModal}
                handleClose={handleClose}
                className={'common-mobile-view'}
                onFinish={createTransactionFee}
                footerText={editModal ? 'Save' : 'Add'}
                form={form}>
                <Form
                    form={form}
                    colon={false}
                    layout="horizontal"
                    className="mt-10 p-1 modal-form">
                    <FormSelect
                        label="Transaction Type:"
                        name="transactionType"
                        defaultValue={formData?.transactionType}
                        disabled={editModal}
                        rules={[{ required: true, message: 'Transaction type is required!' }]}
                        placeholder="Select an option"
                        options={transactionTypes.slice(0, 5).map(item => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        onChange={handleSelectChange}
                    />
                    <FormInput
                        label="% Fee:"
                        name="percentFee"
                        defaultValue={formData?.percentFee}
                        rules={[
                            { required: true, message: 'Transaction fee is required!' },
                            {
                                pattern: /^(?!0+(?:\.0+)?$)(?:\d+|\d*\.\d+)$/,
                                message: 'Transaction fee must be a positive number!',
                            },
                        ]}
                        placeholder="Enter % Fee"
                    />
                    <FormInput
                        label="Flat fee:"
                        name="flatFee"
                        defaultValue={formData?.flatFee}
                        placeholder="Enter flat fee"
                        rules={[
                            {
                                pattern: /^(0|[1-9]\d*(\.\d+)?|\d\.\d+)$/,
                                message: 'Please enter a valid number (0 or positive)!',
                            },
                        ]}
                    />
                    {errorMsg && <div className="text-red-500 mt-3">{errorMsg}</div>}
                </Form>
            </CommonModal>
        </div>
    );
};

export default FeeTab;
