import { Drawer } from 'antd';
import { useWindowWidth } from '../utils/common';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

const siderWidth = {
    width: 236,
    collapsedWidth: 82,
};

export default function DrawerFilters({ children, title, onClose, open, footer, form }) {
    const windowWidth = useWindowWidth();
    const collapsed = useSelector(state => state.config.collapsed);

    return (
        <Drawer
            title={title}
            placement="bottom"
            destroyOnClose
            closable
            className="filter-drawer"
            height={windowWidth > 1100 ? 'auto' : '100%'}
            onClose={onClose}
            style={{
                paddingLeft:
                    windowWidth > 1024
                        ? collapsed
                            ? siderWidth.collapsedWidth
                            : siderWidth.width
                        : 0,
            }}
            open={open}
            footer={windowWidth < 1024 ? footer : null}>
            <Form layout="vertical" className="accounts-filter" form={form}>
                <div className="gap-[16px] grid sm:grid-cols-2 sm:gap-[16px] lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 lg:gap-6">
                    {children}
                </div>
                {windowWidth > 1023 && footer}
            </Form>
        </Drawer>
    );
}
