import { Button, Divider, Form, Input, Modal, Select, Spin, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    LoadingOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { getCurrencySymbol, useWindowWidth } from '../../utils/common';
import TransactionResultModal from './TransactionResultModal';

const CryptoTransactionModal = ({ open, handleClose, onSubmit, selectedCurrency, client }) => {
    const [form] = Form.useForm();
    const windowWidth = useWindowWidth();
    const clientFeesetting = useSelector(state => state.account.clientFeeSetting) || [];
    const balancesData = useSelector(state => state.account.balancesData) || [];
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientFXFee = clientFeesetting.find(e => e.transactionType === 'FX');
    const email = localStorage.getItem('email');
    const [step, setStep] = useState(1);
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [fromCurrency, setFromCurrency] = useState(
        selectedCurrency ? selectedCurrency.currencyShortName : '',
    );
    const [toCurrency, setToCurrency] = useState('');
    const [rate, setRate] = useState(1);
    const [fxFee, setFxFee] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (fromCurrency && toCurrency) {
            fetchExchangeRate(fromCurrency, toCurrency);
        }
    }, [fromCurrency, toCurrency]);

    const fetchExchangeRate = async (from, to) => {
        setLoading(true);
        try {
            const response = await apiRequest('/get-fiat-exchange-rate', 'POST', {
                fromCurrency: from,
                toCurrency: to,
                adminRole: 'PaymentProviderAdmin',
                email,
            });
            let fxRate = response.data.fixedRate;
            const transactionFee = clientFXFee
                ? (Number(fxRate) * clientFXFee.percentFee) / 100
                : 0;
            fxRate = fxRate - transactionFee;
            setFxFee(transactionFee);
            setRate(fxRate);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching exchange rate:', error);
        }
    };

    const createTransaction = async () => {
        try {
            const deductedAmount = fxFee && (Number(toAmount) * fxFee) / rate;
            const body = {
                adminRole: 'PaymentProviderAdmin',
                email,
                client: client,
                type: 'FX',
                currencyType: 'fiat',
                transactionDetails: {
                    fromCurrency: fromCurrency,
                    toCurrency: toCurrency,
                    fromAmount: fromAmount,
                    toAmount: toAmount,
                    fxrate: rate,
                    transactionFee: deductedAmount,
                },
                balance: {
                    updated: new Date(),
                },
            };

            setLoading(true);
            const response = await onSubmit(body);
            handleClose();
            setStep(3);
            if (!response.success) {
                setError(response?.error);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const handleFromAmountChange = e => {
        const value = e.target.value;
        setFromAmount(value);
        setToAmount(value * rate);
        form.setFieldsValue({ to: value * rate });
    };

    const handleToAmountChange = e => {
        const value = e.target.value;
        setFromAmount(value / rate);
        setToAmount(value);
        form.setFieldsValue({ from: value / rate });
    };

    const getCurrencyIconUrl = currency => {
        const currencyData = balancesData.find(data => data.currencyShortName === currency);
        return currencyData ? `${imageBaseUrl}${currencyData.currencyIcon}` : '';
    };

    const getCurrencyFullName = currency => {
        const currencyData = balancesData.find(data => data.currencyShortName === currency);
        return currencyData ? currencyData.currencyFullName : '';
    };

    const onCancel = () => {
        setToAmount('');
        setToCurrency('');
        setFromAmount('');
        setFromCurrency('');
        setRate('');
        setStep(1);
        form.resetFields(); // Reset form fields
        setError('');
    };

    useEffect(() => {
        if (selectedCurrency) {
            setFromCurrency(selectedCurrency.currencyShortName);
        }
    }, [selectedCurrency, balancesData]);

    return (
        <>
            <Modal
                className="transaction-modal deposit-modal"
                width={570}
                centered
                title={
                    step === 1 ? (
                        'Buy Crypto'
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => setStep(1)}
                            />
                            <p className="text-2xl font-semibold relative flex">FX Confirmation</p>
                        </div>
                    ) : null
                }
                open={open}
                onCancel={() => {
                    handleClose();
                    onCancel();
                }}
                handleClose={() => {
                    handleClose();
                    onCancel();
                }}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        handleClose();
                                        onCancel();
                                    }}
                                    className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="primary"
                                loading={step === 2 && loading}
                                onClick={async () => {
                                    try {
                                        await form.validateFields(); // Validate form fields
                                        if (step === 1) {
                                            setStep(2);
                                        } else {
                                            createTransaction();
                                        }
                                    } catch (error) {
                                        console.error('Validation failed:', error);
                                    }
                                }}
                                className="rounded-full px-8 py-3 w-full sm:w-auto h-[46px]"
                                data-e2e={
                                    step === 1
                                        ? 'Continue'
                                        : `${loading ? 'Processing' : 'Exchange'}`
                                }>
                                {step === 1 ? 'Continue' : `${loading ? 'Processing' : 'Exchange'}`}
                            </Button>
                        </div>
                    )
                }>
                {step === 1 ? (
                    <Form
                        form={form}
                        labelCol={{ style: { fontSize: '16px' } }}
                        colon={false}
                        layout="horizontal"
                        className="my-12 p-2 max-h-[80vh] overflow-y-auto">
                        <Form.Item
                            label="You get:"
                            name="from"
                            rules={[
                                { required: true, message: 'Please enter the amount' },
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (value && value <= 0) {
                                            return Promise.reject(
                                                new Error('The amount must be greater than 0'),
                                            );
                                        }
                                        const balance = balancesData.find(
                                            x => x.currencyShortName === fromCurrency,
                                        );
                                        if (balance.balanceAmount < value) {
                                            return Promise.reject(
                                                new Error(
                                                    'Please adjust the value as you have an insufficient balance',
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <div className="flex gap-0.5 !h-[50px]">
                                <Tooltip
                                    title={
                                        !toCurrency || !fromCurrency
                                            ? 'Please select the currencies first'
                                            : ''
                                    }>
                                    <Input
                                        type="number"
                                        disabled={!toCurrency || !fromCurrency}
                                        height={50}
                                        placeholder={
                                            !toCurrency || !fromCurrency
                                                ? 'Select currencies first'
                                                : 'Enter from amount'
                                        }
                                        data-e2e-from={
                                            !toCurrency || !fromCurrency
                                                ? 'Select currencies first'
                                                : 'Enter from amount'
                                        }
                                        className="flex-1 w-full rounded-tl-[25px] rounded-bl-[25px] rounded-tr-[0] rounded-br-[0] pl-6 py-2.5 bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] focus:shadow-none"
                                        value={fromAmount}
                                        onChange={handleFromAmountChange}
                                    />
                                </Tooltip>
                                <Form.Item
                                    key={fromCurrency}
                                    name="fromCurrency"
                                    rules={[{ required: true, message: '' }]}
                                    initialValue={fromCurrency}
                                    noStyle>
                                    <Select
                                        className="custom-rounded-select h-full max-w-[126px] w-full rounded-tr-[25px] rounded-br-[25px] bg-[#F4F4F5] border-none hover:bg-[#F4F4F5"
                                        value={fromCurrency}
                                        data-e2e="from-drop-down"
                                        dropdownStyle={{ zIndex: 1002 }}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                        options={balancesData.map(data => ({
                                            value: data.currencyShortName,
                                            label: (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    <img
                                                        src={`${imageBaseUrl}${data.currencyIcon}`}
                                                        alt={data.currencyShortName}
                                                        data-e2e={data.currencyShortName}
                                                        style={{
                                                            width: '20px',
                                                            marginRight: '10px',
                                                        }}
                                                    />
                                                    {data.currencyShortName}
                                                </div>
                                            ),
                                        }))}
                                        onChange={setFromCurrency}
                                    />
                                </Form.Item>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="You pay:"
                            name="to"
                            rules={[{ required: true, message: 'Please enter the amount' }]}>
                            <div className="flex gap-0.5 !h-[50px]">
                                <Select
                                    className="full-rounded-select h-full  w-full !rounded-[25px]  bg-[#F4F4F5] border-none hover:bg-[#F4F4F5"
                                    value={toCurrency}
                                    data-e2e="to-drop-down"
                                    dropdownStyle={{ zIndex: 1002 }}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    options={(balancesData || []).map(data => ({
                                        value: data.currencyShortName,
                                        label: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <img
                                                    src={`${imageBaseUrl}${data.currencyIcon}`}
                                                    alt={data.currencyShortName}
                                                    data-e2e={data.currencyShortName}
                                                    style={{
                                                        width: '20px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                {data.currencyShortName}
                                            </div>
                                        ),
                                    }))}
                                    onChange={setToCurrency}
                                />
                            </div>
                        </Form.Item>

                        <div className="mt-[30px] flex justify-between items-center">
                            <p className="flex-1 text-[#26272B] text-base">Rate:</p>
                            <div className="flex gap-2 items-center">
                                {loading && (
                                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                                )}
                                {fromCurrency && toCurrency && (
                                    <p className="text-base font-medium text-[#26272B]">{`1 ${fromCurrency} = ${rate} ${toCurrency}`}</p>
                                )}
                            </div>
                        </div>

                        <div className="mt-[30px] flex justify-between items-center">
                            <p className="flex-1 text-[#26272B] text-base">Fee:</p>
                            <div className="flex gap-2 items-center">
                                <p className="text-base font-medium text-[#26272B]">0.2%</p>
                            </div>
                        </div>
                        <Divider className="my-6" />
                        <div className="mt-[30px] flex justify-between items-center">
                            <p className="flex-1 text-[#26272B] text-base">Total:</p>
                            <p className="text-base font-medium text-[#26272B]">
                                {`${getCurrencySymbol(toCurrency)}  ${toAmount} ${toCurrency && `(${toCurrency})`}`}
                            </p>
                        </div>
                    </Form>
                ) : step === 2 ? (
                    <div className="my-12">
                        <div className="sm:border border-[#D1D1D6] rounded-md">
                            <div className="p-3 pb-0">
                                <p className="text-base text-[#3F3F46] mb-5">
                                    Transaction information:
                                </p>
                                <p className="text-[#51525C] mb-2">From:</p>
                                <div className="flex sm:justify-between gap-2.5 sm:gap-0 items-center mb-4">
                                    <span className="flex items-center  space-x-2">
                                        <img
                                            src={getCurrencyIconUrl(fromCurrency)}
                                            className="w-7 h-7 rounded-2xl"
                                            alt={fromCurrency}
                                        />
                                        <p className="text-xl text-[#26272B] hidden sm:block">
                                            {getCurrencyFullName(fromCurrency)}
                                        </p>
                                    </span>
                                    <p className="font-semibold text-xl text-[#26272B]">
                                        {`${getCurrencySymbol(fromCurrency)} ${fromAmount} (${fromCurrency})`}
                                    </p>
                                </div>
                                <Divider className="my-3" />
                                <p className="text-[#51525C] mb-2">To:</p>
                                <div className="flex sm:justify-between gap-2.5 sm:gap-0 items-center mb-4">
                                    <span className="flex items-center space-x-2">
                                        <img
                                            src={getCurrencyIconUrl(toCurrency)}
                                            className="w-7 h-7 rounded-2xl"
                                            alt={toCurrency}
                                        />
                                        <p className="text-xl text-[#26272B] hidden sm:block">
                                            {' '}
                                            {getCurrencyFullName(toCurrency)}
                                        </p>
                                    </span>
                                    <p className="font-semibold text-xl text-[#26272B]">
                                        {`${getCurrencySymbol(toCurrency)} ${toAmount} (${toCurrency})`}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 p-3 sm:bg-[#FAFAFA] border-t border-[#D1D1D6] sm:flex justify-between items-center">
                                <p className="text-base mb-3 sm:mb-0">Rate: </p>
                                <p className="text-primary text-xl font-semibold sm:font-medium sm:text-base">{`1 ${fromCurrency} = ${rate} ${toCurrency}`}</p>
                            </div>
                            <div className=" sm:hidden  mt-5 p-3 sm:bg-[#FAFAFA] border-t border-[#D1D1D6] ">
                                <p className="text-base mb-3 sm:mb-0">Total: </p>
                                <p className="text-primary text-xl font-semibold sm:font-medium sm:text-base">{`${getCurrencySymbol(toCurrency)}  ${toAmount} ${toCurrency && `(${toCurrency})`}`}</p>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Modal>

            <TransactionResultModal
                type={error ? error : 'success'}
                open={step === 3}
                handleClick={onCancel}
                title={error ? 'Error!' : 'Successful!'}
                message={error ? error : 'Has been exchanged successfully!'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {`${getCurrencySymbol(fromCurrency)} ${fromAmount} ${fromCurrency}`}
                        </p>
                        <p className="text-base text-[#3F3F46] text-center">
                            {` (${getCurrencySymbol(toCurrency)} ${toAmount} ${toCurrency})`}
                        </p>
                    </>
                )}
            </TransactionResultModal>
        </>
    );
};

export default CryptoTransactionModal;
