import { Checkbox, Form, message, Space, Typography } from 'antd';
import CommonDragger from './Dragger';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormInput, FormSelectCurrency, FormTextarea } from '../forms';

const WithdrawalAndTPPForm = ({
    formValue,
    balancesData,
    selectedCurrency,
    onCurrencyChange,
    fileList,
    setFileList,
    isSavedAccount,
    toggleSavedAccount,
    isAccountEditing,
    isWithdrawToSaveAccount,
    deleteAttachment,
    form,
}) => {
    const imgBaseUrl = useSelector(state => state.config.image_base_url);

    useEffect(() => {
        if (selectedCurrency) {
            form.setFieldsValue({
                currency: selectedCurrency?.currencyShortName,
            });
        }
    }, [selectedCurrency]);

    return (
        <Form
            form={form}
            labelCol={{ style: { fontSize: '16px' }, flex: '180px' }}
            colon={false}
            layout="horizontal"
            className="modal-form h-full lg:mt-[30px] mt-[36px] px-1">
            {isAccountEditing && (
                <FormInput
                    label="Account nickname:"
                    name="accountNickName"
                    defaultValue={formValue.accountNickName}
                    placeholder="Enter account nickname"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="Beneficiary name:"
                    name="beneficiaryName"
                    defaultValue={formValue.beneficiaryName}
                    placeholder="Enter beneficiary name"
                    dataE2e="enter-banificiary-name"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="Beneficiary address:"
                    name="beneficiaryAddress"
                    defaultValue={formValue.beneficiaryAddress}
                    placeholder="Enter beneficiary address"
                    dataE2e="enter-benefciary-address"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="Bank name:"
                    name="bankName"
                    defaultValue={formValue.bankName}
                    placeholder="Enter bank name"
                    dataE2e="enter-bank-name"
                />
            )}
            {isSavedAccount && (
                <FormInput
                    label="Account Nickname:"
                    name="accountNickName"
                    rules={[
                        {
                            required: true,
                            message: 'Account Nickname is required',
                        },
                    ]}
                    defaultValue={formValue.accountNickName}
                    placeholder="Enter account nickname"
                    dataE2e="enter-account-nickname"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <>
                    <FormInput
                        label="Bank address:"
                        name="bankAddress"
                        defaultValue={formValue.bankAddress}
                        placeholder="Enter bank address"
                        dataE2e="enter-bank-address"
                    />
                    {!isAccountEditing && (
                        <div className="w-full sm:w-[320px] ml-auto">
                            <Space>
                                <Checkbox
                                    className="mt-[10px] text-sm lg:text-base"
                                    checked={isSavedAccount}
                                    dataE2e="save-this.account"
                                    onChange={e => toggleSavedAccount(e.target.checked)}>
                                    Save this Bank Account
                                </Checkbox>
                            </Space>
                        </div>
                    )}
                </>
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="Account number:"
                    name="accountNumber"
                    defaultValue={formValue.accountNumber}
                    placeholder="Enter account number"
                    dataE2e="enter-account-number"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="Sort code:"
                    name="sortCode"
                    defaultValue={formValue.sortCode}
                    placeholder="Enter sort code"
                    dataE2e="enter-sort-code"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="IBAN:"
                    name="iban"
                    defaultValue={formValue.iban}
                    placeholder="Enter IBAN"
                    dataE2e="enter-iban"
                />
            )}
            {!isWithdrawToSaveAccount && (
                <FormInput
                    label="SWIFT:"
                    name="swift"
                    defaultValue={formValue.swift}
                    placeholder="Enter SWIFT code"
                    dataE2e="enter-swift-code"
                />
            )}
            {isWithdrawToSaveAccount && (
                <>
                    <FormInput
                        label="Withdraw to:"
                        name="accountNickName"
                        defaultValue={formValue.accountNickName}
                        placeholder="Enter account nickname"
                        dataE2e="enter-account-nick-name"
                    />
                    <div className="relative w-full sm:w-[320px] ml-auto">
                        <Space className="mt-[10px]">
                            <Typography.Text>
                                Receiver: {`${form.getFieldValue('beneficiaryName')}` || '---'}
                            </Typography.Text>
                        </Space>
                    </div>
                </>
            )}
            {!isAccountEditing && (
                <FormSelectCurrency
                    label="Amount:"
                    name="amount"
                    type="text"
                    selectName="currency"
                    required
                    rules={[
                        { required: true, message: 'Please enter an amount' },
                        {
                            validator: async (_, value) => {
                                const numValue = Number(value);
                                if (isNaN(numValue) || numValue <= 0) {
                                    return Promise.reject(
                                        new Error('The amount must be the number greater than 0'),
                                    );
                                }
                                const balance = balancesData.find(
                                    currency =>
                                        currency.currencyShortName ===
                                        selectedCurrency.currencyShortName,
                                );
                                if (balance?.balanceAmount < numValue) {
                                    return Promise.reject(
                                        new Error(
                                            'Please adjust the value as you have an insufficient balance',
                                        ),
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                    placeholder="Enter amount"
                    dataE2e="enter-amount"
                    selectDefaultValue={selectedCurrency.currencyShortName}
                    options={(balancesData || []).map(data => ({
                        value: data.currencyShortName,
                        label: (
                            <div className="flex items-center">
                                <img
                                    src={`${imgBaseUrl}${data.currencyIcon}`}
                                    alt={data.currencyShortName}
                                    className="w-6 mr-[10px]"
                                />
                                {data.currencyShortName}
                            </div>
                        ),
                    }))}
                    onSelectChange={e => onCurrencyChange(e)}
                />
            )}
            <FormTextarea
                label="Additional info:"
                name="additionalInfo"
                formClassName="additional-info"
                rows={3}
                defaultValue={formValue.additionalInfo}
                placeholder="EG: routing number"
            />
            <FormInput
                label="Reference:"
                name="reference"
                defaultValue={formValue.reference}
                placeholder="Enter your reference"
            />
            {!isAccountEditing && (
                <React.Fragment>
                    <div className="mt-[20px] mb-[20px] h-[1px] bg-[#E4E4E7]" />
                    <span className="text-sm lg:text-base"> Attachments: </span>
                    <Form.Item className="mt-5">
                        <CommonDragger
                            name="file"
                            multiple={true}
                            fileList={fileList}
                            setFileList={setFileList}
                            deleteAttachment={deleteAttachment}
                            onChange={info => {
                                const { status } = info.file;
                                if (status !== 'uploading') {
                                    setFileList([...info.fileList]);
                                }
                                if (status === 'done') {
                                    message.success(
                                        `${info.file.name} file uploaded successfully.`,
                                    );
                                } else if (status === 'error') {
                                    message.error(`${info.file.name} file upload failed.`);
                                }
                            }}
                        />
                    </Form.Item>
                </React.Fragment>
            )}
        </Form>
    );
};

export default WithdrawalAndTPPForm;
