import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ThemeButton from '../common/ThemeButton';

export default function FormMessage({ title, description, buttonTitle }) {
    const navigate = useNavigate();

    return (
        <div className="auth__block">
            <div className="auth__message-icon">
                <CheckCircleFilled />
            </div>
            <div>
                <h2 className="auth__message-title">{title}</h2>
                <p className="auth__subtitle" dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
            <ThemeButton
                action={() => navigate('/')}
                text={buttonTitle}
                type="primary"
                className="auth__submit-message"
            />
        </div>
    );
}
