import { EditOutlined, BankOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';

const SavedBeneficiaryAccounts = ({
    savedBeneficiaryAccounts,
    openEditAccountModal,
    withdrawToSaveAccount,
}) => {
    return (
        <List
            className="custom-beneficiary-list lg:mt-[30px] mt-[36px]"
            itemLayout="horizontal"
            dataSource={savedBeneficiaryAccounts}
            renderItem={item => (
                <List.Item
                    actions={[
                        <Button
                            key={item._id}
                            icon={<EditOutlined onClick={() => openEditAccountModal(item)} />}
                        />,
                    ]}>
                    <List.Item.Meta
                        avatar={
                            <BankOutlined data-e2e={item.accountNickName} className="text-2xl" />
                        }
                        title={item.accountNickName}
                        description={item.beneficiaryAddress}
                        onClick={() => withdrawToSaveAccount(item)}
                    />
                </List.Item>
            )}
        />
    );
};

export default SavedBeneficiaryAccounts;
