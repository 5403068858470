import { Button, Modal, Space } from 'antd';
import React from 'react';
import { DeleteOutlined, CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';

const DeleteBankAccount = ({ isLoading, open, cancelDeleteAccount, deleteBeneficiaryAccount }) => {
    return (
        <Modal
            style={{ maxHeight: 'calc(100vh - 200px)', padding: '0' }}
            className={'delete-bank-account-modal'}
            width="422px"
            styles={{ body: { overflowY: 'scroll' } }}
            open={open}
            destroyOnClose
            closeIcon={false}
            centered
            footer={
                <div className="flex gap-[12px] justify-between modal__footer p-[24px] bg-[#F4F4F5]">
                    <Button
                        className="lg:w-[180px] h-[46px] rounded-3xl bg-[#E4E4E7] text-base font-medium"
                        onClick={cancelDeleteAccount}>
                        Cancel
                    </Button>
                    <Button
                        loading={isLoading}
                        iconPosition="end"
                        className="lg:w-[180px] h-[46px] rounded-3xl bg-[#F04438] text-white text-base font-medium"
                        onClick={deleteBeneficiaryAccount}>
                        {isLoading ? 'Deleting...' : 'Delete'}
                    </Button>
                </div>
            }>
            <Space direction={'vertical'} className="mt-[48px] flex justify-center">
                <div className="relative flex justify-center items-center">
                    <div className="rounded-full bg-red-100 w-[68px] h-[68px] flex justify-center items-center">
                        <div className="rounded-full bg-red-200 w-[58px] h-[58px] flex justify-center items-center">
                            <DeleteOutlined className="text-[34px] text-[#D92D20]" />
                        </div>
                    </div>
                </div>
                <p className="text-center text-2xl font-semibold mt-[24px]">Delete Bank Account</p>
                <p className="text-center text-lg mt-[12px]">
                    Are you sure you want to delete this bank account?
                </p>
            </Space>
        </Modal>
    );
};

export default DeleteBankAccount;
