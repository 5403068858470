import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthCode from 'react-auth-code-input';
import apiRequest from '../../utils/api';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AdminLogo } from '../../utils/Images';

const JunoFaAuthenticator = () => {
    const navigate = useNavigate();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [error, setError] = useState('');
    const [verificationCode2fa, change2faVerificationCode] = useState('');
    const [imageData, setImageData] = useState(null);
    const [secret, setSecret] = useState(null);
    const [loading, setLoading] = useState(false);

    const getSecretKey = async () => {
        const body = {
            userEmail: localStorage.getItem('email'),
            hostName: 'Juno Admin: ',
        };
        const response = await apiRequest('/generate-2fa-secreat-code', 'POST', body);
        if (response.data) {
            setSecret(response.data);
        }
    };

    useEffect(() => {
        getSecretKey();
    }, []);

    useEffect(() => {
        if (secret) {
            generateQRcode();
        }
    }, [secret]);

    const generateQRcode = async () => {
        let imageData = null;
        await qrcode.toDataURL(secret.otpauth_url, function (err, imgData) {
            if (err) {
                console.error(err);
                alert('Something went wrong!');
            } else if (imgData) {
                imageData = imgData;
            }
        });
        setImageData(imageData);
    };

    const onVerifyCode = async () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            setLoading(true);
            const body = {
                email: localStorage.getItem('email'),
                user2faToken: verificationCode2fa,
                changeActivation: 'activate',
                secret: secret.base32,
                encoding: 'base32',
                authenticator: true,
                adminRole: localStorage.getItem('adminRole'),
            };

            const response = await apiRequest('/admin-user-verify-2fa-token', 'POST', body);

            if (response.success && response.data) {
                setLoading(false);
                if (response.data.success) {
                    localStorage.setItem('2FAStatus', true);
                    navigate('/dashboard');
                } else {
                    setError('Invalid verification code!');
                }
            } else {
                setError(response.error);
            }
        } else {
            setError('Invalid token! 2FA token must have 6-digit only');
        }
    };

    const disabledVerify = verificationCode2fa.length !== 6;

    return (
        <div className="w-[644px] p-8 bg-white rounded-lg">
            <img src={`${imageBaseUrl}${AdminLogo}`} className="w-36 mx-auto" />
            <div className="w-[360px] mx-auto">
                <h2 className="mt-7 text-4xl font-semibold text-center text-primary">
                    Two-factor authentification
                </h2>
                <p className="mt-3 text-[#667085] font-medium text-center">
                    Scan the QR Code in Google&apos;s Authenticator app and input the verification
                    code.
                </p>
            </div>
            <form>
                <div className="flex justify-center">
                    <img src={imageData}></img>
                </div>

                <div className="theme-modal-field">
                    <div className="authCode mt-5">
                        <AuthCode
                            containerClassName="authCode-container"
                            onChange={change2faVerificationCode}
                        />
                    </div>
                    {error !== '' && (
                        <div className="text-[#df4444] mb-2.5 text-sm text-center">{error}</div>
                    )}
                </div>
                <div className="w-[360px] mx-auto">
                    <Button
                        loading={loading}
                        onClick={() => {
                            if (!disabledVerify) {
                                onVerifyCode();
                            }
                        }}
                        disabled={disabledVerify}
                        type="primary"
                        className="w-full rounded-full h-11 font-semibold text-base">
                        Verify
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default JunoFaAuthenticator;
