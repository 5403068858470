import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiRequest from '../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import FormLogo from '../components/forms/FormLogo';
import FormMessage from '../components/forms/FormMessage';
import ThemeButton from '../components/common/ThemeButton';

const ResetPassword = () => {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const { token } = useParams();
    const [userEmail, setuserEmail] = useState('');

    const navigate = useNavigate();

    const updatePassword = async values => {
        const body = {
            verifyToken: token,
            email: userEmail,
            newPassword: values.confirmPassword,
        };
        setLoading(true);
        try {
            await apiRequest('/admin-new-password', 'POST', body);
            setStep(2);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    useEffect(() => {
        if (token) {
            verifyToken(token);
        }
    }, [token]);

    const verifyToken = async token => {
        const body = {
            verifyToken: token,
        };
        try {
            const response = await apiRequest('/admin-forgot-password', 'POST', body);
            if (response.data.success) {
                setuserEmail(response.data.email);
            } else {
                navigate('/');
            }
        } catch (error) {
            navigate('/');
            console.error('Error posting data:', error);
        }
    };

    return (
        <div className="auth">
            {step === 1 && (
                <div className="auth__block">
                    <FormLogo />
                    <h2 className="auth__title">Enter new password</h2>
                    <div className="auth__form">
                        <Form
                            layout="vertical"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={updatePassword}
                            autoComplete="off">
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}>
                                <Input.Password
                                    placeholder="******"
                                    className="h-11 px-3.5 py-2.5"
                                />
                            </Form.Item>
                            <Form.Item
                                label="Confirm password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords do not match!'),
                                            );
                                        },
                                    }),
                                ]}>
                                <Input.Password
                                    placeholder="******"
                                    className="h-11 px-3.5 py-2.5"
                                />
                            </Form.Item>
                            <ThemeButton
                                loading={loading}
                                disabled={loading}
                                type="primary"
                                htmlType="submit"
                                text="Reset password"
                                shape="round"
                                size="large"
                            />
                        </Form>
                    </div>
                </div>
            )}
            {step === 2 && (
                <FormMessage
                    title="Password updated"
                    description={`Password for <span>${userEmail}</span> has been updated, return and log in to Juno Admin.`}
                    buttonTitle="Return to log in"
                />
            )}
        </div>
    );
};

export default ResetPassword;
