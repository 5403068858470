import { Button, Input, Table, message } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomPagination from '../layout/CustomPagination';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete } from '../../utils/Images';
import apiRequest from '../../utils/api';
import LinkMerchantModal from './LinkMerchantModal';
import { LoadingSkeleton } from '../common/Loading';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';

const LinkedMerchants = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState('');
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const email = localStorage.getItem('email');

    const getMerchants = async () => {
        setLoading(true);
        try {
            const body = {
                clientId: clientData._id,
                onlyLinked: true,
                email,
                searchQuery: searchText,
                page: pagination.current,
            };
            const res = await apiRequest('/fetch-merchant', 'POST', body);
            if (res.success) {
                setData(res.data.merchants);
                setPagination(prev => ({ ...prev, total: res.data.totalCount }));
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const linkMerchantsToClient = async selectedMerchants => {
        setLoading(true);
        try {
            const res = await apiRequest('/link-merchant', 'POST', {
                clientId: clientData._id,
                merchantIds: selectedMerchants,
                email,
            });

            if (res.success) {
                getMerchants();
                setModal(false);
                setLoading(false);
            } else {
                message.error(res.error);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const deleteMerchant = async ({ merchantId, unlinkAllMerchants }) => {
        setLoading(true);
        const body = {
            clientId: clientData._id,
            merchantId,
            unlinkAllMerchants,
            email,
        };
        try {
            await apiRequest('/unlink-merchant', 'POST', body);
            if (pagination.current === 1) getMerchants();
            else setPagination(pre => ({ ...pre, current: 1 }));
            setLoading(false);
            modalClose();
        } catch (err) {
            setLoading(false);
            console.log(err.message, 'Fetch Error');
        }
    };

    const modalClose = () => {
        setDeleteModal(false);
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: 'ID',
            dataIndex: 'merchantId',
            key: 'merchantId',
        },
        {
            key: 'actions',
            render: record => (
                <>
                    <Button
                        className="border-0"
                        onClick={() => {
                            setDeleteModal(true);
                            setSelectedRecord(record);
                        }}
                        icon={<DeleteOutlined />}></Button>
                </>
            ),
        },
    ];

    useEffect(() => {
        const getData = setTimeout(() => {
            if (clientData._id) {
                getMerchants();
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [searchText, pagination.current, clientData._id]);

    const handleTableChange = newPagination => {
        const { current } = newPagination;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));
    };
    return (
        <>
            <div className="my-6">
                <h1 className="text-[#18181B] font-semibold text-lg">Linked Merhchants</h1>
                <p className="text-base font-normal text-[#51525C]">
                    Manage merchants that are linked to{' '}
                    <span className="text-[#18181B] font-medium">{clientData.name}</span>
                </p>
            </div>
            <div className="flex justify-between items-center gap-2">
                <Input
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    placeholder="Search Account ID, Account name"
                    suffix={<SearchOutlined />}
                    className="md:w-[400px] w-4/5 p-2"
                />
                <div className="hidden md:flex">
                    <ThemeButton
                        type="primary"
                        text="Link New"
                        action={() => setModal(true)}
                        icon={<PlusOutlined />}
                        style="mr-2"
                    />
                    {data.length > 0 && (
                        <Button
                            className="py-2 px-3 h-auto"
                            onClick={() => {
                                setSelectedRecord('');
                                setDeleteModal(true);
                            }}>
                            Remove All
                        </Button>
                    )}
                </div>
                <div className="flex md:hidden md:w-1/5">
                    <Button
                        type="primary"
                        className="py-2 px-3 h-auto bg-[#18181B] mr-2"
                        icon={<PlusOutlined />}
                        onClick={() => setModal(true)}
                    />
                    {data.length > 0 && (
                        <Button
                            onClick={() => {
                                setSelectedRecord('');
                                setDeleteModal(true);
                            }}
                            className="py-2 px-3 h-auto"
                            icon={
                                <img
                                    src={`${imageBaseUrl}${Delete}`}
                                    alt="Delete"
                                    className="inline"
                                    width={24}
                                    height={24}
                                />
                            }
                        />
                    )}
                </div>
            </div>
            <div className="mt-6 accounts-page md:block hidden">
                <Table
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    pagination={
                        pagination.total > 9
                            ? {
                                  position: ['bottomCenter'],
                                  itemRender: CustomPagination,
                                  showSizeChanger: false,
                                  current: pagination.current,
                                  pageSize: 10,
                                  total: pagination.total,
                              }
                            : false
                    }
                    onChange={handleTableChange}
                />
            </div>
            <div className="md:hidden">
                {loading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        {data.length ? (
                            data.map(item => (
                                <div
                                    className="flex justify-between items-center px-3 py-5 border-b"
                                    key={item._id}>
                                    <div className="">
                                        <h1 className="text-sm font-medium text-[#18181B]">
                                            {item.merchantName}
                                        </h1>
                                        <p className="text-sm font-medium text-[#70707B]">
                                            ID:{item.merchantId}
                                        </p>
                                    </div>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setDeleteModal(true);
                                            setSelectedRecord(item);
                                        }}>
                                        <img
                                            src={`${imageBaseUrl}${Delete}`}
                                            alt="Delete"
                                            className="inline"
                                            width={20}
                                            height={20}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className=" p-2.5 pr-6">
                                <p className="theme-table-empty">No data found!</p>
                            </div>
                        )}
                    </>
                )}
            </div>
            {open && (
                <LinkMerchantModal
                    clientId={clientData._id}
                    open={modal}
                    onClose={() => {
                        setModal(false);
                    }}
                    onSave={selectedMerchants => linkMerchantsToClient(selectedMerchants)}
                    onReset={() => {
                        setModal(false);
                        setDeleteModal(true);
                    }}
                />
            )}
            <DeleteModal
                open={deleteModal}
                title={
                    selectedRecord && selectedRecord._id
                        ? 'Delete merchants'
                        : 'Remove linked merchants'
                }
                content={
                    selectedRecord && selectedRecord._id ? (
                        <>
                            Are you sure you would like to delete{' '}
                            <span className="font-semibold">{selectedRecord.merchantName}</span>{' '}
                            from the merchant list?
                        </>
                    ) : (
                        `Are you sure you want to remove ${data.length} linked merchants? This action cannot be undone.`
                    )
                }
                toDelete={selectedRecord._id}
                handleClose={modalClose}
                handleDelete={merchantId =>
                    deleteMerchant({
                        merchantId: merchantId,
                        unlinkAllMerchants: !(selectedRecord && selectedRecord._id),
                    })
                }
            />
        </>
    );
};

export default LinkedMerchants;
