import { Button, Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import {
    getCurrencySymbol,
    toFixedTrunc,
    useWindowWidth,
    validateAmount,
    removeCommas,
    getCryptoFee,
    handleCryptoExchangeRate,
    cryptoCurrencyIcon,
} from '../../utils/common';
import TransactionResultModal from './TransactionResultModal';
import { CryptoSelectModal } from './CryptoSelectModal';
import { useNavigate } from 'react-router-dom';
import CryptoPendingModal from './CryptoPendingModal';

const SellCryptoTransactionModal = ({ open, handleClose, onSubmit, client }) => {
    const [formData, setFormData] = useState({
        cryptoAmount: null,
        fiatAmount: null,
        cryptoId: 'ETH',
        fiatCurrency: 'USD',
    });
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const [form] = Form.useForm();
    const windowWidth = useWindowWidth();
    const balancesCurrencies = useSelector(state => state.account.balancesData) || [];
    const fiatIcon = balancesCurrencies.find(
        x => x.currencyShortName === formData.fiatCurrency,
    ).currencyIcon;
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const email = localStorage.getItem('email');

    const [step, setStep] = useState(1);
    const [rate, setRate] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [exchangeRate, setExchangeRate] = useState(1);
    const [fee, setFee] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [exchangeLoading, setExchangeLoading] = useState(false);
    const [quotationData, setQuotationData] = useState(0);
    const [feeError, setFeeError] = useState('');
    const [processingModal, setProcessingModal] = useState({ open: false, msg: '' });
    const navigate = useNavigate();

    const createTransaction = async () => {
        try {
            const blockchain = balancesCrypto.find(
                crypto => crypto.currencyShortName === formData.cryptoId,
            )?.blockchain;
            const body = {
                currencyType: 'crypto',
                type: 'Sell',
                clientId: client?.clientId,
                email: localStorage.getItem('email'),
                transactionEmail: client.email,
                transactionFee: parseFloat(removeCommas(transactionFee)).toFixed(2),
                transactionDetails: {
                    cryptoAmount: parseFloat(removeCommas(formData.cryptoAmount)).toFixed(2),
                    fiatAmount: parseFloat(removeCommas(formData.fiatAmount)).toFixed(2),
                    cryptoId: formData.cryptoId,
                    blockchain,
                    fiatCurrency: formData.fiatCurrency,
                    exchangeRate,
                    fee: fee * 100,
                    totalAmount: parseFloat(removeCommas(totalAmount)).toFixed(2),
                    quotationId: quotationData?.rfq_id,
                    quotationPrice: quotationData?.price,
                },
            };
            setLoading(true);
            const response = await onSubmit(body);
            if (response.success) {
                setStep(3);
            } else if (!response?.error.includes('processing')) {
                setError(response?.error);
                setStep(3);
            } else {
                setProcessingModal({ open: true, msg: response?.error });
            }
        } catch (error) {
            setIsLoading(false);
            setError(error.message || 'An error occurred while creating the transaction');
        }
    };
    const handleAmountChange = e => {
        if (exchangeRate) {
            let cryptoAmount, fiatAmount, amountError;
            const name = e.target.name;
            const value = e.target.value;

            if (name === 'fiatAmount') {
                cryptoAmount = exchangeRate && removeCommas(value) * exchangeRate;
                cryptoAmount = toFixedTrunc(cryptoAmount, 2);
                fiatAmount = value;
                amountError = validateAmount(
                    removeCommas(cryptoAmount),
                    0,
                    formData.cryptoId,
                    balancesCrypto,
                );
            } else if (name === 'cryptoAmount') {
                const fixedRate = 1 / exchangeRate;
                fiatAmount = fixedRate && removeCommas(value) * fixedRate;
                fiatAmount = toFixedTrunc(fiatAmount, 2);
                cryptoAmount = value;
                amountError = validateAmount(value, 0, formData.cryptoId, balancesCrypto);
            }
            setFormData({ ...formData, cryptoAmount, fiatAmount });
            setTransactionFee(removeCommas(fiatAmount) * fee);
            setAmountError(amountError);
        }
    };

    const handleExchangeRate = async () => {
        setExchangeLoading(true);
        const payload = {
            cryptoList: [formData.cryptoId],
            fiatAmount: 1,
            fiatCurrency: formData.fiatCurrency,
            exchangeConversionType: 'fiat-to-crypto',
        };
        const response = await handleCryptoExchangeRate(payload);
        if (response.cryptoAmounts) {
            setExchangeRate(response.cryptoAmounts[formData.cryptoId]);
        }
        setExchangeLoading(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            if (!formData.cryptoId || !open) return;
            if (formData.fiatCurrency) {
                await handleExchangeRate();
            }
            const payload = {
                email: email,
                clientEmail: client.clientEmail,
                crypto: formData.cryptoId,
                profile: client.feeProfile,
                transactionType: 'Sell',
            };
            try {
                const cryptoFee = await getCryptoFee(payload);
                setFee(cryptoFee && !cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
                setFeeError(cryptoFee.error ? cryptoFee.message.validationErrors : '');
            } catch (error) {
                console.error('Failed to fetch crypto fee:', error);
            }
        };
        fetchData();
    }, [formData.cryptoId, formData.fiatCurrency, open]);

    useEffect(() => {
        const { fiatAmount } = formData;

        if (formData && fee >= 0) {
            const parsedFiatAmount = fiatAmount && parseFloat(removeCommas(fiatAmount));
            const transactionFee = parsedFiatAmount * parseFloat(fee);
            const totalAmount = parsedFiatAmount - transactionFee;
            setTransactionFee(toFixedTrunc(transactionFee, 2));
            setTotalAmount(toFixedTrunc(totalAmount, 2));
        }
    }, [formData, fee]);

    const handleAmountAndSetFormData = (type, currencyType) => {
        if (type == 'crypto') {
            setFormData({
                ...formData,
                cryptoId: currencyType,
            });
        } else {
            setFormData({
                ...formData,
                fiatCurrency: currencyType,
            });
        }
    };

    useEffect(() => {
        let intervalId = null;
        if (step == 2) {
            intervalId = setInterval(() => {
                handleQuotationAmount();
            }, 10000);
        } else {
            clearInterval(intervalId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [step]);
    const handleQuotationAmount = async () => {
        setIsLoading(true);
        const blockchain = balancesCrypto.find(
            crypto => crypto.currencyShortName === formData.cryptoId,
        )?.blockchain;
        const payload = {
            email: email,
            clientEmail: client.clientEmail,
            userId: client?.accountNumber,
            crypto: formData.cryptoId,
            blockchain: blockchain,
            cryptoAmount: formData.cryptoAmount,
            side: 'Sell',
            fiat: formData.fiatCurrency,
        };
        const response = await apiRequest('/request-for-quotation', 'POST', { ...payload });
        if (response.success) {
            setExchangeRate(1 / parseFloat(response?.data?.data?.price));
            setQuotationData(response?.data?.data);
            setFormData({
                ...formData,
                fiatAmount: toFixedTrunc(
                    formData.cryptoAmount * parseFloat(response?.data?.data?.price),
                    2,
                ),
            });
        } else {
            console.error();
        }
        setIsLoading(false);
    };
    const onCancel = () => {
        setFormData({
            ...formData,
            cryptoAmount: null,
            fiatAmount: null,
            cryptoId: 'ETH',
            fiatCurrency: 'USD',
        });
        setExchangeRate(0);
        setRate(1);
        setStep(1);
        form.resetFields();
        setError('');
    };
    const handleStatus = () => {
        navigate('/transactions', {
            state: {
                clientId: client?.clientId,
            },
        });
    };

    return (
        <>
            <Modal
                className="sell-crypto-modal common-mobile-view "
                width={570}
                centered
                title={
                    step === 1 ? (
                        <p className="text-2xl font-semibold relative flex">Sell Crypto</p>
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => setStep(1)}
                            />
                            <p className="text-2xl font-semibold relative flex">Sell Crypto</p>
                        </div>
                    ) : null
                }
                open={open && step !== 3}
                onCancel={() => {
                    onCancel();
                    handleClose();
                }}
                handleClose={() => {
                    onCancel();
                    handleClose();
                }}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        onCancel();
                                        handleClose();
                                    }}
                                    className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="primary"
                                loading={step === 2 && loading}
                                disabled={
                                    !formData.cryptoAmount ||
                                    (amountError && amountError.length > 0) ||
                                    (feeError && feeError.length > 0)
                                }
                                onClick={async () => {
                                    try {
                                        if (step === 1) {
                                            setStep(2);
                                            handleQuotationAmount();
                                        } else {
                                            createTransaction();
                                        }
                                    } catch (error) {
                                        console.error('Validation failed:', error);
                                    }
                                }}
                                className="rounded-full px-8 py-3 w-full sm:!w-[140px] h-[46px]"
                                data-e2e={
                                    step === 1
                                        ? 'Continue'
                                        : `${loading ? 'Processing' : 'Sell Now'}`
                                }>
                                {step === 1 ? 'Continue' : `${loading ? 'Processing' : 'Sell Now'}`}
                            </Button>
                        </div>
                    )
                }>
                {step === 1 ? (
                    <>
                        <div className="mt-6 flex justify-between items-center max-sm:flex-wrap">
                            <label className="sell-crypto-label">Sell:</label>
                            <div className="sell-crypto-label-value">
                                <input
                                    type="text"
                                    name="cryptoAmount"
                                    data-e2e="sell-field"
                                    onChange={handleAmountChange}
                                    value={formData.cryptoAmount}
                                    className="sell-crypto-input-btn"
                                />
                                <CryptoSelectModal
                                    value={formData.cryptoId}
                                    onChange={e => handleAmountAndSetFormData('crypto', e)}
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        {amountError && (
                            <div className="jncAlert alert" role="alert">
                                {amountError}
                            </div>
                        )}
                        <div className="my-[30px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="sell-crypto-label">Receive:</label>
                            <div className="sell-crypto-label-value">
                                <input
                                    type="text"
                                    name="fiatAmount"
                                    data-e2e="receive-field"
                                    onChange={handleAmountChange}
                                    value={formData.fiatAmount}
                                    className="sell-crypto-input-btn"
                                />
                                <CryptoSelectModal
                                    value={formData.fiatCurrency}
                                    onChange={e => handleAmountAndSetFormData('fiat', e)}
                                    options={balancesCurrencies}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="sell-crypto-title">
                            <label className="sell-crypto-label">Rate:</label>
                            <div className="sell-crypto-value">
                                {exchangeLoading && (
                                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                                )}
                                <span>
                                    1 {formData.cryptoId} = {toFixedTrunc(1 / exchangeRate, 2)}{' '}
                                    {formData.fiatCurrency}
                                </span>
                            </div>
                        </div>
                        <div className="sell-crypto-title">
                            <label className="sell-crypto-label">Fee:</label>
                            <div className="sell-crypto-value">
                                {exchangeLoading && (
                                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                                )}
                                <span>{fee * 100}%</span>
                            </div>
                        </div>
                        {feeError && (
                            <div className="jncAlert alert" role="alert">
                                Fee profile must be one of [Standard, VIP, Super VIP, Bespoke]
                            </div>
                        )}
                        <hr className=" my-4" />
                        <div className="mt-4 sell-crypto-title">
                            <label className="sell-crypto-label">Total:</label>
                            <div className="sell-crypto-value">
                                <span className="text-xl font-semibold">
                                    {getCurrencySymbol(formData.fiatCurrency)} {totalAmount || 0} (
                                    {formData.fiatCurrency})
                                </span>
                            </div>
                        </div>
                    </>
                ) : step === 2 ? (
                    <>
                        <div className="sm:border rounded-lg p-3 sm:mt-10 mb-[30px]">
                            <h1 className="hidden sm:block text-base font-semibold">
                                Transaction information:
                            </h1>
                            <div className="mt-5">
                                <p className="sell-crypto-confirm-p">Sell:</p>
                                <div className="sell-crypto-confirm">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${cryptoCurrencyIcon[formData.cryptoId]}`}
                                            alt="img"
                                            className=""
                                            width={24}
                                            height={24}
                                        />
                                        <span className="sell-crypto-confirm-span">
                                            {formData.cryptoId}
                                        </span>
                                    </div>
                                    <p className="sell-crypto-confirm-currency">
                                        {getCurrencySymbol(formData.cryptoId)}{' '}
                                        {formData.cryptoAmount} ({formData.cryptoId})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <p className="sell-crypto-confirm-p">Receive:</p>
                                <div className="sell-crypto-confirm">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${fiatIcon}`}
                                            width={24}
                                            height={24}
                                            alt="img"
                                            className=""
                                        />
                                        <span className="sell-crypto-confirm-span">
                                            {formData.fiatCurrency}
                                        </span>
                                    </div>
                                    <p className="sell-crypto-confirm-currency">
                                        {getCurrencySymbol(formData.fiatCurrency)}{' '}
                                        {formData.fiatAmount} ({formData.fiatCurrency})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="sell-crypto-confirm-title">
                                <p className="sell-crypto-confirm-p">Rate:</p>
                                <p className="sell-crypto-confirm-value">
                                    {isLoading && (
                                        <Spin indicator={<LoadingOutlined spin />} size="small" />
                                    )}
                                    1 {formData.cryptoId} = {toFixedTrunc(1 / exchangeRate, 2)}{' '}
                                    {formData.fiatCurrency}
                                </p>
                            </div>
                            <hr className="block sm:hidden my-3" />
                            <div className="mt-3 sell-crypto-confirm-title">
                                <p className="sell-crypto-confirm-p">Fee:</p>
                                <p className="sell-crypto-confirm-value">{fee * 100}%</p>
                            </div>
                            <hr className="my-3" />
                            <div className="sell-crypto-confirm-title">
                                <p className="sell-crypto-confirm-p">Total:</p>
                                <p className="py-2 text-xl font-semibold text-[#18181B]">
                                    {getCurrencySymbol(formData.fiatCurrency)} {totalAmount || 0} (
                                    {formData.fiatCurrency})
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}
            </Modal>

            <TransactionResultModal
                type={error ? error : 'success'}
                open={open && step === 3}
                title={error ? 'Error!' : 'Sell Confirmed!'}
                handleClick={() => {
                    onCancel();
                    handleClose();
                }}
                message={error ? error : 'Has been deposited into your Juno Money wallet.'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {getCurrencySymbol(formData.fiatCurrency)} {totalAmount || 0} (
                            {formData.fiatCurrency})
                        </p>
                    </>
                )}
            </TransactionResultModal>
            <CryptoPendingModal
                open={processingModal.open}
                onCancel={() => setProcessingModal(false)}
                error={processingModal.msg}
                handleConfirm={() => {
                    handleStatus();
                }}
            />
        </>
    );
};

export default SellCryptoTransactionModal;
