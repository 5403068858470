import { Modal } from 'antd';
import { CopyButton, useCommonFunctions } from '../../utils/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import qrcode from 'qrcode';
import { CryptoSelectModal } from './CryptoSelectModal';
import apiRequest from '../../utils/api';

export const ReceiveCryptoModal = ({ open, handleClose }) => {
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState(null);
    const [selectedCrypto, setSelectedCrypto] = useState('ETH');
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const { clientData } = useSelector(state => state.account);
    const [imageData, setImageData] = useState(null);
    const email = localStorage.getItem('email');
    const { getCryptoBalances } = useCommonFunctions();

    const generateQRcode = async address => {
        await qrcode.toDataURL(address?.key, function (err, imgData) {
            if (err) {
                console.error(err);
                alert('Something went wrong!');
            } else if (imgData) {
                setImageData(imgData);
            }
        });
    };

    useEffect(() => {
        if (balancesCrypto && open) {
            const walletAddress = balancesCrypto.find(
                crypto => crypto.currencyShortName === selectedCrypto,
            )?.walletAddress;
            if (walletAddress) {
                setSelectedCryptoAddress(walletAddress);
                generateQRcode(walletAddress);
            } else {
                setSelectedCryptoAddress(null);
            }
        }
    }, [balancesCrypto, open, selectedCrypto]);

    const updateCryptoAddress = async () => {
        const blockchain = balancesCrypto.find(
            crypto => crypto.currencyShortName === selectedCrypto,
        )?.blockchain;

        const body = {
            clientId: clientData?.clientId,
            cryptoId: selectedCrypto,
            blockchain,
            accountNumber: clientData?.accountNumber,
            email,
        };

        const response = await apiRequest('/update-wallet-address', 'POST', body);
        if (response.success) {
            getCryptoBalances();
        }
    };

    return (
        <Modal
            open={open}
            destroyOnClose
            className="transaction-modal"
            title={'Receive Crypto'}
            onCancel={handleClose}
            footer={false}
            handleClose={handleClose}>
            <div className="flex mt-12 justify-between items-center max-md:flex-col gap-[30px]">
                <img
                    src={imageData}
                    alt="walletAddress"
                    className="w-[160px] h-[160px] p-2 rounded-2xl border"
                />
                <div className="max-md:text-center">
                    <h1 className="text-base font-semibold text-[#18181B]">
                        Only send Tether (USDT) to this address
                    </h1>
                    <p className="lg:mt-3 text-base font-normal text-[#3F3F46]">
                        Sending any other asset, including USD Coin (USDC), will result in permanent
                        loss.
                    </p>
                </div>
            </div>
            <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                <h1 className="text-base font-normal text-[#26272B] col-span-1">
                    Recipient wallet:
                </h1>
                <div className="col-span-2">
                    <CryptoSelectModal
                        value={selectedCrypto}
                        onChange={e => setSelectedCrypto(e)}
                        options={balancesCrypto}
                        className="w-full !rounded-l-3xl max-sm:!w-full"
                    />
                </div>
            </div>
            <div className="border rounded-lg p-4 mt-[40px] w-full">
                <h1 className="text-base font-light text-[#51525C] ">Receiving address:</h1>
                {!selectedCryptoAddress ? (
                    <div className="">
                        <p className="text-[#D92D20] text-base font-medium mt-1">Not generated</p>
                        <button
                            onClick={updateCryptoAddress}
                            data-e2e="generate-btn"
                            className="w-full border border-[#D1D1D6] text-base font-medium rounded-full py-[11px] mt-6">
                            Generate
                        </button>
                    </div>
                ) : (
                    <p className="grid grid-cols-4 ">
                        <div className="col-span-3 truncate">{selectedCryptoAddress?.key}</div>
                        <div className="col-span-1 justify-self-end">
                            <CopyButton msg={selectedCryptoAddress?.key} />
                        </div>
                    </p>
                )}
            </div>
        </Modal>
    );
};
