// src/routes/routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/Homepage';
import Dashboard from '../pages/Dashboard';
import Accounts from '../pages/Accounts';
import Transactions from '../pages/Transactions';
import Reports from '../pages/Reports';
import Withdrawals from '../pages/Withdrawals';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import AccountDetails from '../pages/AccountDetails';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import TransactionDetail from '../pages/TransactionDetail';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicRoute component={HomePage} restricted />} />
            <Route path="/forgot-password" element={<PublicRoute component={ForgotPassword} />} />
            <Route
                exact
                path="/admin-forgot-password/:token"
                element={<PublicRoute component={ResetPassword} />}
            />

            {/* Protected routes */}
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/accounts" element={<ProtectedRoute component={Accounts} />} />
            <Route path="/transactions" element={<ProtectedRoute component={Transactions} />} />
            <Route path="/reports" element={<ProtectedRoute component={Reports} />} />
            <Route path="/withdrawals" element={<ProtectedRoute component={Withdrawals} />} />
            <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
            <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
            <Route path="/accounts/:id" element={<ProtectedRoute component={AccountDetails} />} />
            <Route
                path="/transactions/:transactionId"
                element={<ProtectedRoute component={TransactionDetail} />}
            />
        </Routes>
    );
};

export default AppRoutes;
