import React from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CustomPagination = (current, type, originalElement) => {
    if (type === 'prev') {
        return (
            <>
                <Button
                    className="border border-[#E4E4E7] lg:block hidden"
                    iconPosition="start"
                    icon={<LeftOutlined />}>
                    Previous
                </Button>
                <Button
                    className="border border-[#E4E4E7] lg:hidden block"
                    icon={<LeftOutlined />}></Button>
            </>
        );
    }
    if (type === 'next') {
        return (
            <Button className="border border-[#E4E4E7]" icon={<RightOutlined />} iconPosition="end">
                Next
            </Button>
        );
    }
    if (type === 'page') {
        return <p className="">{originalElement}</p>;
    }
    return originalElement;
};

export default CustomPagination;
