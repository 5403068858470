import { getDate } from '../../utils/common';
import { SET_DATE_FILTER } from '../actions/actionTypes';
import moment from 'moment';

const now = moment();

const initialState = {
    date: ['1D'],
    startDate: getDate('1D'),
    endDate: now.format('YYYY-MM-DDTHH:mm:ssZ'),
};

const dateFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATE_FILTER: {
            const { date, startDate, endDate } = action.payload;
            return {
                ...state,
                date: date,
                startDate:
                    startDate || now.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
                endDate: endDate || now.format('YYYY-MM-DDTHH:mm:ssZ'),
            };
        }
        default:
            return state;
    }
};

export default dateFilterReducer;
