import React, { useState } from 'react';
import { Divider } from 'antd';
import Sidebar from '../components/layout/Sidebar';
import { Tabs } from 'antd';
import WhiteLabelUsers from '../components/settingsTabs/WhiteLabelUsers';
import WhiteLabel from '../components/settingsTabs/WhiteLabel';

const Settings = () => {
    const [tab, setTab] = useState(0);
    return (
        <>
            <Sidebar>
                <div className="bg-[#F7F7F7] hidden lg:block h-full w-full settings-tab">
                    <div className="lg:px-[24px] h-full py-[16px] ">
                        {/* Desktop Screen */}
                        <div className="desktop-page-block hidden lg:flex flex-col bg-white rounded-xl">
                            <div className="flex items-center justify-between p-6">
                                <h2 className="page-title">Global Settings</h2>
                            </div>
                            <Tabs
                                defaultActiveKey={tab}
                                onChange={num => {
                                    setTab(num);
                                }}
                                items={[
                                    {
                                        key: '0',
                                        label: 'White Label',
                                        children: <WhiteLabel tab={tab} />,
                                    },
                                    {
                                        key: '1',
                                        label: 'White Label Users',
                                        children: <WhiteLabelUsers tab={tab} />,
                                    },
                                ]}
                                className="mb-0"
                            />
                        </div>
                    </div>
                </div>

                <div className="lg:hidden">
                    <h2 className="page-title px-3 py-4">Global Settings</h2>
                    <Divider className="m-0" />
                    <div className="bg-[#F7F7F7]">
                        <Tabs
                            defaultActiveKey={tab}
                            onChange={num => {
                                setTab(num);
                            }}
                            items={[
                                {
                                    key: '0',
                                    label: 'White Label',
                                    children: <WhiteLabel tab={tab} />,
                                },
                                {
                                    key: '1',
                                    label: 'White Label Users',
                                    children: <WhiteLabelUsers tab={tab} />,
                                },
                            ]}
                            className="transaction-detail account-tabs-mob"
                        />
                    </div>
                </div>
            </Sidebar>
        </>
    );
};

export default Settings;
