import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import apiRequest from '../utils/api';
import { Link } from 'react-router-dom';
import FormLogo from '../components/forms/FormLogo';
import FormMessage from '../components/forms/FormMessage';
import ThemeButton from '../components/common/ThemeButton';

const ForgotPassword = () => {
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const forgotPassword = async values => {
        const body = {
            email: values.email,
            hostname: window.location.hostname,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/admin-forgot-password', 'POST', body);

            if (response.success) {
                message.success(response.data.msg);
                setStep(2);
            } else {
                setError(response.error);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    return (
        <div className="auth">
            {step === 1 && (
                <div className="auth__block">
                    <FormLogo />
                    <h2 className="auth__title">Forgot password</h2>
                    <p className="auth__subtitle">Enter your email and we’ll send a reset link.</p>
                    <div className="auth__form">
                        <Form
                            layout="vertical"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={forgotPassword}
                            autoComplete="off">
                            <Form.Item
                                label="Email"
                                name="email"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!',
                                    },
                                ]}>
                                <Input
                                    placeholder="Enter your email"
                                    className="h-11 px-3.5 py-2.5 !bg-transparent"
                                    data-e2e="email-field"
                                />
                            </Form.Item>
                            {error !== '' && <div className="auth__errorText">{error}</div>}
                            <ThemeButton
                                loading={loading}
                                type="primary"
                                shape="round"
                                text="Send recovery link"
                                dataE2e="send-recovery-email"
                                htmlType="submit"
                                size="large"
                            />
                            <p className="auth__remembered">
                                Remembered password?
                                <Link to="/">Login</Link>
                            </p>
                        </Form>
                    </div>
                </div>
            )}
            {step === 2 && (
                <FormMessage
                    title=" Check your email"
                    description={`Please check the email address <span>email@gmail.com</span> for instruction to reset your password`}
                    buttonTitle="Continue"
                />
            )}
        </div>
    );
};

export default ForgotPassword;
