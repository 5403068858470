import React from 'react';
import { CloudUploadOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { Upload, Space, Typography } from 'antd';
const { Dragger } = Upload;

const CommonDragger = props => {
    return (
        <>
            <Dragger
                beforeUpload={file => {
                    props.setFileList([...props.fileList, file]);
                    return false;
                }}
                listType="picture"
                showUploadList={false}
                className="upload-list-inline"
                customRequest={() => {
                    setTimeout(() => {
                        return true;
                    }, 2000);
                }}
                // action={props.action}
                {...props}>
                <div className="relative flex justify-center items-center">
                    <div className="rounded-full bg-gray-100 w-[44px] h-[44px] flex justify-center items-center">
                        <div className="rounded-full bg-gray-200 w-[34px] h-[34px] flex justify-center items-center">
                            <CloudUploadOutlined className="text-xl" />
                        </div>
                    </div>
                </div>
                <p className="ant-upload-text font-semibold">
                    Click to upload{' '}
                    <span className="text-[#70707B] font-normal">or drug and drop</span>
                </p>
                <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
            </Dragger>
            {props.fileList.length > 0 &&
                props.fileList.map(file => {
                    return (
                        <Typography
                            key={file.uid}
                            className="rounded-lg border border-gray-300 mt-5 w-full p-[10px]">
                            <Space className="w-full flex justify-between">
                                <Space>
                                    <FileOutlined className="text-base" />
                                    <div className="flex flex-col ml-[16px]">
                                        <Typography.Text>{file.name}</Typography.Text>
                                        <Typography.Text className="text-[#70707B]">{`${file.size} KB`}</Typography.Text>
                                    </div>
                                </Space>
                                <DeleteOutlined
                                    className="text-base cursor-pointer"
                                    onClick={() => props.deleteAttachment(file.uid)}
                                />
                            </Space>
                        </Typography>
                    );
                })}
        </>
    );
};
export default CommonDragger;
