import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from '@redux-devtools/extension';

const initializeStore = preloadedState => {
    // eslint-disable-next-line no-undef
    // const composeEnhancers = process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;

    const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware()));

    return store;
};

export default initializeStore;
