import { UPDATE_FILTERS, RESET_FILTERS } from './actionTypes';

export const updateFilters = filters => ({
    type: UPDATE_FILTERS,
    payload: filters,
});

export const resetFilters = () => ({
    type: RESET_FILTERS,
    payload: {
        name: [],
        clientEmail: [],
        phoneNumber: [],
        active: [],
        clientId: [],
        statusCode: [],
        transactionType: [],
        transactionStatus: [],
    },
});
