import React from 'react';
import { Button, Drawer, Flex } from 'antd';

const NotificationDrawer = ({ onClose, open }) => {
    return (
        <Drawer title="Notifications" onClose={onClose} open={open}>
            <Flex gap="small" wrap>
                <Button color="" className="!px-[8px] !text-[13px] font-[500] leading-[18px]">
                    Critical Event
                </Button>
                <Button color="" className="!px-[8px] !text-[13px] font-[500] leading-[18px]">
                    Warning
                </Button>
                <Button color="" className="!px-[8px] !text-[13px] font-[500] leading-[18px]">
                    Failure
                </Button>
                <Button color="" className="!px-[8px] !text-[13px] font-[500] leading-[18px]">
                    Suspicious
                </Button>
            </Flex>
        </Drawer>
    );
};

export default NotificationDrawer;
