import { Skeleton, Spin } from 'antd';
import React from 'react';

export const LoadingSkeleton = () => {
    return <Skeleton className="bg-white p-5" active></Skeleton>;
};

export const LoadingAvatarSkeleton = () => {
    return <Skeleton avatar active paragraph={{ rows: 1 }} className="bg-white p-2 rounded" />;
};

const Loading = () => {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <Spin size="large" />
        </div>
    );
};

export default Loading;
