export default function CopyIcon() {
    return (
        <svg
            className="inline"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="6.74933"
                y="6.74951"
                width="9.75074"
                height="9.75098"
                rx="1.87502"
                stroke="#70707B"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.75023 11.251H3.00018C2.17168 11.251 1.50006 10.5793 1.50006 9.75083V3.00015C1.50006 2.17164 2.17168 1.5 3.00018 1.5H9.75069C10.5792 1.5 11.2508 2.17164 11.2508 3.00015V3.75023"
                stroke="#70707B"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
